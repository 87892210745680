import Vue from "vue";
import VueRouter from "vue-router";
// 引入组件
import index from "@/views/index/index.vue";
import Frame from "@/components/Frame.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: index,
  },
  {
    path: "/talent",
    name: "talent",
    component: Frame,
    children: [
      {
        path: "index",
        name: "index",
        component: () => import("@/views/talent/index.vue"),
      },
      {
        path: "list/:type",
        name: "list",
        component: () => import("@/views/talent/list.vue"),
      },
    ],
  },
  {
    path: "/goods",
    name: "goods",
    component: Frame,
    children: [
      {
        path: "index",
        name: "index",
        component: () => import("@/views/goods/index.vue"),
      },
      {
        path: "list/:type",
        name: "list",
        component: () => import("@/views/goods/list.vue"),
      },
    ],
  },
  {
    path: "/live",
    name: "live",
    component: Frame,
    children: [
      {
        path: "index",
        name: "index",
        component: () => import("@/views/live/index.vue"),
      },
      {
        path: "list/:type",
        name: "list",
        component: () => import("@/views/live/list.vue"),
      },
    ],
  },
  {
    path: "/video",
    name: "video",
    component: Frame,
    children: [
      {
        path: "index",
        name: "index",
        component: () => import("@/views/video/index.vue"),
      },
      {
        path: "Bring",
        name: "Bring",
        component: () => import("@/views/video/Bring.vue"),
      },
      {
        path: "list/:type",
        name: "list",
        component: () => import("@/views/video/list.vue"),
      },
    ],
  },
  {
    path: "/shop",
    name: "shop",
    component: Frame,
    children: [
      {
        path: "index",
        name: "index",
        component: () => import("@/views/shop/index.vue"),
      },
      {
        path: "list/:type",
        name: "list",
        component: () => import("@/views/shop/list.vue"),
      },
    ],
  },
  {
    path: "/brand",
    name: "brand",
    component: Frame,
    children: [
      {
        path: "index",
        name: "index",
        component: () => import("@/views/brand/index.vue"),
      },
      {
        path: "list/:type",
        name: "list",
        component: () => import("@/views/brand/list.vue"),
      },
    ],
  },
];

routes.push({
  path: "*",
  name: "404",
  component: () => import("@/views/404View.vue"),
});
console.log(routes);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// 路由的前置守卫
router.beforeEach((to, from, next) => {
  if (to.name !== from.name) {
    next();
  }
});

export default router;
