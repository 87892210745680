<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div id="Folwline" style="width: 700px; height: 326px"></div>
</template>
<script>
//  直播流量折线图
import * as echarts from "echarts";
import zhiboshuju from "./zhiboshuju.json";
export default {
  name: "Folwline",
  data() {
    return {
      xdata: [],
      CurrentValues: [],
      YesterdayValues: [],
    };
  },
  methods: {
    // 初始化数据
    initdate() {
      zhiboshuju.forEach((item) => {
        this.xdata.push(item.Time);
        this.CurrentValues.push(item.Value);
        this.YesterdayValues.push(item.OValue);
      });
      this.initChart();
    },
    // 定义函数
    initChart() {
      console.log(echarts);
      const chart = echarts.init(document.getElementById("Folwline"));
      const option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["观众热度", "昨日同期"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {
              // 隐藏保存按钮
              show: false,
            },
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            lineStyle: {
              color: "#999",
            },
          },
        },
        xAxis: {
          axisline: {
            lineStyle: {
              color: "#999",
            },
          },
          type: "category",
          boundaryGap: false,
          data: this.xdata,
        },
        series: [
          {
            name: "观众热度",
            type: "line",
            stack: "Total",
            data: this.CurrentValues,
            showSymbol: false, // 是否显示点
            // 颜色
            itemStyle: {
              normal: {
                color: "#ff4457",
              },
            },
          },
          {
            name: "昨日同期",
            type: "line",
            stack: "Total",
            data: this.YesterdayValues,
            showSymbol: false, //隐藏点的显示
            lineStyle: {
              type: "dotted",
            },
            itemStyle: {
              normal: {
                color: "#8e8e8e",
              },
            },
          },
        ],
      };
      //   chart.setOption(option);
      option && chart.setOption(option);
    },
  },
  mounted() {
    this.initdate();
  },
};
</script>

<style scoped>
/* scoped 让style样式 局部生效*/
</style>
