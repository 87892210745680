<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="connect">
    <div class="rank-content-wrapper flex mt15">
      <div class="flex-1 rank-box relative mr15">
        <div class="rank-title">
          <div
            class="flex align-items-center justify-content-space-between pt15"
          >
            <span class="fs20 font-weight-600 c333">热门直播间</span>
            <div
              class="link-btn-wrapper flex align-items-center cursor-pointer"
            >
              <span class="text fs12 c999 font-weight-400 mr5">完整榜单</span
              ><i class="arrow el-icon-arrow-right"></i>
            </div>
          </div>
          <div class="mt5">
            <span class="fs12 font-weight-600 c999"> 2023-12-11 </span>
          </div>
        </div>
        <div class="rank-list">
          <div class="property-label-content flex">
            <div class="label-item pr40">观看人次</div>
            <div class="label-item">销售额</div>
          </div>
          <div class="rank-item">
            <div
              class="rank-index flex align-items-center justify-content-center"
            >
              <img
                src="//oss-image2.quanmamaimg.com/AdminImageUpload/2023/3/1/2415789top1_ORIGIN_zczN.png"
                alt="ddd-img"
                class="rank-index-img"
              />
            </div>
            <div class="flex-1 flex rank-info mr5">
              <a
                href="/live/liveDetail?id=7311119818776677146"
                tag="a"
                target="_blank"
                class="relative block text-decoration-none mr8"
                ><div>
                  <img
                    alt="ddd-img"
                    class="img-lazy rank-img circle"
                    data-src="http://image1.daduoduo.com/Room/202312/e31f91dbe54d48478c1c6a600bfb3ff1?x-oss-process=image/resize,h_150,w_150"
                    src="http://image1.daduoduo.com/Room/202312/e31f91dbe54d48478c1c6a600bfb3ff1?x-oss-process=image/resize,h_150,w_150"
                    lazy="loaded"
                  /></div
              ></a>
              <div class="flex flex-direction-column justify-content-center">
                <a
                  href="/live/liveDetail?id=7311119818776677146"
                  tag="a"
                  target="_blank"
                  class="fs14 font-weight-500 c333 text-decoration-none ellipsis-1 link-hover"
                  ><div
                    class="el-tooltip"
                    aria-describedby="el-tooltip-8159"
                    tabindex="0"
                  >
                    正在上新
                  </div></a
                >
                <span
                  title="7小时32分43秒"
                  class="ellipsis-1 info-property mt5"
                >
                  直播7小时32分43秒
                </span>
              </div>
            </div>
            <div class="property-content">
              <div class="property-item mr10">
                <span class="mt5 fs14 font-weight-600 c333 cds-font lh100p">
                  60.9w
                </span>
              </div>
              <div class="property-item" style="min-width: 100px">
                <span class="mt5 fs14 font-weight-600 c333 cds-font lh100p">
                  2500w~5000w
                </span>
              </div>
            </div>
          </div>
          <div class="rank-item">
            <div
              class="rank-index flex align-items-center justify-content-center"
            >
              <img
                src="//oss-image2.quanmamaimg.com/AdminImageUpload/2023/3/1/2541297top2_ORIGIN_2kDN.png"
                alt="ddd-img"
                class="rank-index-img"
              />
            </div>
            <div class="flex-1 flex rank-info mr5">
              <a
                href="/live/liveDetail?id=7311197291677748007"
                tag="a"
                target="_blank"
                class="relative block text-decoration-none mr8"
                ><div>
                  <img
                    alt="ddd-img"
                    class="img-lazy rank-img circle"
                    data-src="http://image1.daduoduo.com/Room/202312/3ccea93fa4b844b0a12ccea09f89d3d4?x-oss-process=image/resize,h_150,w_150"
                    src="http://image1.daduoduo.com/Room/202312/3ccea93fa4b844b0a12ccea09f89d3d4?x-oss-process=image/resize,h_150,w_150"
                    lazy="loaded"
                  /></div
              ></a>
              <div class="flex flex-direction-column justify-content-center">
                <a
                  href="/live/liveDetail?id=7311197291677748007"
                  tag="a"
                  target="_blank"
                  class="fs14 font-weight-500 c333 text-decoration-none ellipsis-1 link-hover"
                  ><div
                    class="el-tooltip"
                    aria-describedby="el-tooltip-7351"
                    tabindex="0"
                  >
                    左上角福袋有10台苹果14
                  </div></a
                >
                <span
                  title="1天1小时48分45秒"
                  class="ellipsis-1 info-property mt5"
                >
                  直播1天1小时48分45秒
                </span>
              </div>
            </div>
            <div class="property-content">
              <div class="property-item mr10">
                <span class="mt5 fs14 font-weight-600 c333 cds-font lh100p">
                  1,959.1w
                </span>
              </div>
              <div class="property-item" style="min-width: 100px">
                <span class="mt5 fs14 font-weight-600 c333 cds-font lh100p">
                  1000w~2500w
                </span>
              </div>
            </div>
          </div>
          <div class="rank-item">
            <div
              class="rank-index flex align-items-center justify-content-center"
            >
              <img
                src="//oss-image2.quanmamaimg.com/AdminImageUpload/2023/3/1/261466top3_ORIGIN_0gjM.png"
                alt="ddd-img"
                class="rank-index-img"
              />
            </div>
            <div class="flex-1 flex rank-info mr5">
              <a
                href="/live/liveDetail?id=7311102872937040678"
                tag="a"
                target="_blank"
                class="relative block text-decoration-none mr8"
                ><div>
                  <img
                    alt="ddd-img"
                    class="img-lazy rank-img circle"
                    data-src="http://image1.daduoduo.com/Room/202312/979b1ee3b5964bbfb2c1bc796d4e1590?x-oss-process=image/resize,h_150,w_150"
                    src="http://image1.daduoduo.com/Room/202312/979b1ee3b5964bbfb2c1bc796d4e1590?x-oss-process=image/resize,h_150,w_150"
                    lazy="loaded"
                  /></div
              ></a>
              <div class="flex flex-direction-column justify-content-center">
                <a
                  href="/live/liveDetail?id=7311102872937040678"
                  tag="a"
                  target="_blank"
                  class="fs14 font-weight-500 c333 text-decoration-none ellipsis-1 link-hover"
                  ><div
                    class="el-tooltip"
                    aria-describedby="el-tooltip-2315"
                    tabindex="0"
                  >
                    高品质女装用心感受得到
                  </div></a
                >
                <span
                  title="8小时40分23秒"
                  class="ellipsis-1 info-property mt5"
                >
                  直播8小时40分23秒
                </span>
              </div>
            </div>
            <div class="property-content">
              <div class="property-item mr10">
                <span class="mt5 fs14 font-weight-600 c333 cds-font lh100p">
                  70.4w
                </span>
              </div>
              <div class="property-item" style="min-width: 100px">
                <span class="mt5 fs14 font-weight-600 c333 cds-font lh100p">
                  1000w~2500w
                </span>
              </div>
            </div>
          </div>
          <div class="rank-item">
            <div
              class="rank-index flex align-items-center justify-content-center"
            >
              <span class="cds-font fs14 font-weight-600 c999"> 4 </span>
            </div>
            <div class="flex-1 flex rank-info mr5">
              <a
                href="/live/liveDetail?id=7311120269454756608"
                tag="a"
                target="_blank"
                class="relative block text-decoration-none mr8"
                ><div>
                  <img
                    alt="ddd-img"
                    class="img-lazy rank-img circle"
                    data-src="http://image1.daduoduo.com/Room/202312/a2e40bf78a99460b92fbd853e0c4d9fe?x-oss-process=image/resize,h_150,w_150"
                    src="http://image1.daduoduo.com/Room/202312/a2e40bf78a99460b92fbd853e0c4d9fe?x-oss-process=image/resize,h_150,w_150"
                    lazy="loaded"
                  /></div
              ></a>
              <div class="flex flex-direction-column justify-content-center">
                <a
                  href="/live/liveDetail?id=7311120269454756608"
                  tag="a"
                  target="_blank"
                  class="fs14 font-weight-500 c333 text-decoration-none ellipsis-1 link-hover"
                  ><div
                    class="el-tooltip"
                    aria-describedby="el-tooltip-3244"
                    tabindex="0"
                  >
                    老杨的私服正在直播
                  </div></a
                >
                <span
                  title="6小时28分47秒"
                  class="ellipsis-1 info-property mt5"
                >
                  直播6小时28分47秒
                </span>
              </div>
            </div>
            <div class="property-content">
              <div class="property-item mr10">
                <span class="mt5 fs14 font-weight-600 c333 cds-font lh100p">
                  53.9w
                </span>
              </div>
              <div class="property-item" style="min-width: 100px">
                <span class="mt5 fs14 font-weight-600 c333 cds-font lh100p">
                  1000w~2500w
                </span>
              </div>
            </div>
          </div>
          <div class="rank-item">
            <div
              class="rank-index flex align-items-center justify-content-center"
            >
              <span class="cds-font fs14 font-weight-600 c999"> 5 </span>
            </div>
            <div class="flex-1 flex rank-info mr5">
              <a
                href="/live/liveDetail?id=7311281099311811378"
                tag="a"
                target="_blank"
                class="relative block text-decoration-none mr8"
                ><div>
                  <img
                    alt="ddd-img"
                    class="img-lazy rank-img circle"
                    data-src="http://image1.daduoduo.com/Room/202312/75d25d5d554a4e5bacf433255c929e9d?x-oss-process=image/resize,h_150,w_150"
                    src="http://image1.daduoduo.com/Room/202312/75d25d5d554a4e5bacf433255c929e9d?x-oss-process=image/resize,h_150,w_150"
                    lazy="loaded"
                  /></div
              ></a>
              <div class="flex flex-direction-column justify-content-center">
                <a
                  href="/live/liveDetail?id=7311281099311811378"
                  tag="a"
                  target="_blank"
                  class="fs14 font-weight-500 c333 text-decoration-none ellipsis-1 link-hover"
                  ><div
                    class="el-tooltip"
                    aria-describedby="el-tooltip-6793"
                    tabindex="0"
                  >
                    欢姐 0220正在直播
                  </div></a
                >
                <span
                  title="6小时57分13秒"
                  class="ellipsis-1 info-property mt5"
                >
                  直播6小时57分13秒
                </span>
              </div>
            </div>
            <div class="property-content">
              <div class="property-item mr10">
                <span class="mt5 fs14 font-weight-600 c333 cds-font lh100p">
                  51.3w
                </span>
              </div>
              <div class="property-item" style="min-width: 100px">
                <span class="mt5 fs14 font-weight-600 c333 cds-font lh100p">
                  1000w~2500w
                </span>
              </div>
            </div>
          </div>
        </div>
        <!---->
        <!---->
      </div>
      <div class="flex-1 rank-box relative mr15">
        <div class="rank-title">
          <div
            class="flex align-items-center justify-content-space-between pt15"
          >
            <span class="fs20 font-weight-600 c333">抖音热推商品</span>
            <div
              class="link-btn-wrapper flex align-items-center cursor-pointer"
            >
              <span class="text fs12 c999 font-weight-400 mr5">完整榜单</span
              ><i class="arrow el-icon-arrow-right"></i>
            </div>
          </div>
          <div class="mt5">
            <span class="fs12 font-weight-600 c999"> 2023-12-11 </span>
          </div>
        </div>
        <div class="rank-list">
          <div class="property-label-content flex">
            <div class="label-item pr30">佣金</div>
            <div class="label-item">热推达人</div>
          </div>
          <div class="rank-item">
            <div
              class="rank-index flex align-items-center justify-content-center"
            >
              <img
                src="//oss-image2.quanmamaimg.com/AdminImageUpload/2023/3/1/2415789top1_ORIGIN_zczN.png"
                alt="ddd-img"
                class="rank-index-img"
              />
            </div>
            <div class="flex-1 flex rank-info mr5">
              <a
                href="/product/promotionDetail?id=3511453523281863513"
                tag="a"
                target="_blank"
                class="block text-decoration-none mr8"
                ><div>
                  <img
                    alt="ddd-img"
                    class="img-lazy rank-img circle"
                    data-src="http://image1.daduoduo.com/Goods/202311/26525de0237044afad28e92f751b02a3?x-oss-process=image/resize,h_150,w_150"
                    src="http://image1.daduoduo.com/Goods/202311/26525de0237044afad28e92f751b02a3?x-oss-process=image/resize,h_150,w_150"
                    lazy="loaded"
                  /></div
              ></a>
              <div class="flex flex-direction-column justify-content-center">
                <a
                  href="/product/promotionDetail?id=3511453523281863513"
                  tag="a"
                  target="_blank"
                  class="fs12 font-weight-400 c333 text-decoration-none link-hover"
                  ><div
                    class="el-tooltip ellipsis-2"
                    aria-describedby="el-tooltip-1515"
                    tabindex="0"
                  >
                    『诺梵松露/约100颗』松露巧克力圣诞节日送礼物年货糖果零食大礼包
                  </div></a
                >
              </div>
            </div>
            <div class="property-content">
              <div class="property-item mr10">
                <span class="mt5 fs14 font-weight-600 c333 cds-font lh100p">
                  20%
                </span>
              </div>
              <div class="property-item" style="min-width: 90px">
                <div class="mt5 textInline">
                  <span class="fs14 font-weight-600 c333 cds-font lh100p">
                    3,982
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="rank-item">
            <div
              class="rank-index flex align-items-center justify-content-center"
            >
              <img
                src="//oss-image2.quanmamaimg.com/AdminImageUpload/2023/3/1/2541297top2_ORIGIN_2kDN.png"
                alt="ddd-img"
                class="rank-index-img"
              />
            </div>
            <div class="flex-1 flex rank-info mr5">
              <a
                href="/product/promotionDetail?id=3651127765765349190"
                tag="a"
                target="_blank"
                class="block text-decoration-none mr8"
                ><div>
                  <img
                    alt="ddd-img"
                    class="img-lazy rank-img circle"
                    data-src="http://image1.daduoduo.com/Goods/202311/562299213bcf4883a61f87158868f2fb?x-oss-process=image/resize,h_150,w_150"
                    src="http://image1.daduoduo.com/Goods/202311/562299213bcf4883a61f87158868f2fb?x-oss-process=image/resize,h_150,w_150"
                    lazy="loaded"
                  /></div
              ></a>
              <div class="flex flex-direction-column justify-content-center">
                <a
                  href="/product/promotionDetail?id=3651127765765349190"
                  tag="a"
                  target="_blank"
                  class="fs12 font-weight-400 c333 text-decoration-none link-hover"
                  ><div
                    class="el-tooltip ellipsis-2"
                    aria-describedby="el-tooltip-1125"
                    tabindex="0"
                  >
                    【速干蓬松】羽绒服烘干袋
                    快速蓬松吹干神器便携式电吹风快速烘干
                  </div></a
                >
              </div>
            </div>
            <div class="property-content">
              <div class="property-item mr10">
                <span class="mt5 fs14 font-weight-600 c333 cds-font lh100p">
                  30%
                </span>
              </div>
              <div class="property-item" style="min-width: 90px">
                <div class="mt5 textInline">
                  <span class="fs14 font-weight-600 c333 cds-font lh100p">
                    2,159
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="rank-item">
            <div
              class="rank-index flex align-items-center justify-content-center"
            >
              <img
                src="//oss-image2.quanmamaimg.com/AdminImageUpload/2023/3/1/261466top3_ORIGIN_0gjM.png"
                alt="ddd-img"
                class="rank-index-img"
              />
            </div>
            <div class="flex-1 flex rank-info mr5">
              <a
                href="/product/promotionDetail?id=3643775146868765456"
                tag="a"
                target="_blank"
                class="block text-decoration-none mr8"
                ><div>
                  <img
                    alt="ddd-img"
                    class="img-lazy rank-img circle"
                    data-src="http://image1.daduoduo.com/Goods/202310/abe3f52fc05f402e89f8e30be4bd5f8f?x-oss-process=image/resize,h_150,w_150"
                    src="http://image1.daduoduo.com/Goods/202310/abe3f52fc05f402e89f8e30be4bd5f8f?x-oss-process=image/resize,h_150,w_150"
                    lazy="loaded"
                  /></div
              ></a>
              <div class="flex flex-direction-column justify-content-center">
                <a
                  href="/product/promotionDetail?id=3643775146868765456"
                  tag="a"
                  target="_blank"
                  class="fs12 font-weight-400 c333 text-decoration-none link-hover"
                  ><div
                    class="el-tooltip ellipsis-2"
                    aria-describedby="el-tooltip-135"
                    tabindex="0"
                  >
                    【9.9发24枚】红包利是封龙年炫彩卡通镭射立体个性创意儿童压岁包
                  </div></a
                >
              </div>
            </div>
            <div class="property-content">
              <div class="property-item mr10">
                <span class="mt5 fs14 font-weight-600 c333 cds-font lh100p">
                  34%
                </span>
              </div>
              <div class="property-item" style="min-width: 90px">
                <div class="mt5 textInline">
                  <span class="fs14 font-weight-600 c333 cds-font lh100p">
                    2,003
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="rank-item">
            <div
              class="rank-index flex align-items-center justify-content-center"
            >
              <span class="cds-font fs14 font-weight-600 c999"> 4 </span>
            </div>
            <div class="flex-1 flex rank-info mr5">
              <a
                href="/product/promotionDetail?id=3485828470490151013"
                tag="a"
                target="_blank"
                class="block text-decoration-none mr8"
                ><div>
                  <img
                    alt="ddd-img"
                    class="img-lazy rank-img circle"
                    data-src="http://image1.daduoduo.com/Goods/202311/65859d0873984c3aa89ccae2e5e38466?x-oss-process=image/resize,h_150,w_150"
                    src="http://image1.daduoduo.com/Goods/202311/65859d0873984c3aa89ccae2e5e38466?x-oss-process=image/resize,h_150,w_150"
                    lazy="loaded"
                  /></div
              ></a>
              <div class="flex flex-direction-column justify-content-center">
                <a
                  href="/product/promotionDetail?id=3485828470490151013"
                  tag="a"
                  target="_blank"
                  class="fs12 font-weight-400 c333 text-decoration-none link-hover"
                  ><div
                    class="el-tooltip ellipsis-2"
                    aria-describedby="el-tooltip-1793"
                    tabindex="0"
                  >
                    植护气垫纸巾悦色橙抽纸面巾纸餐巾纸大包20包整箱批发家庭实惠装
                  </div></a
                >
              </div>
            </div>
            <div class="property-content">
              <div class="property-item mr10">
                <span class="mt5 fs14 font-weight-600 c333 cds-font lh100p">
                  10%
                </span>
              </div>
              <div class="property-item" style="min-width: 90px">
                <div class="mt5 textInline">
                  <span class="fs14 font-weight-600 c333 cds-font lh100p">
                    1,936
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="rank-item">
            <div
              class="rank-index flex align-items-center justify-content-center"
            >
              <span class="cds-font fs14 font-weight-600 c999"> 5 </span>
            </div>
            <div class="flex-1 flex rank-info mr5">
              <a
                href="/product/promotionDetail?id=3620889142579355421"
                tag="a"
                target="_blank"
                class="block text-decoration-none mr8"
                ><div>
                  <img
                    alt="ddd-img"
                    class="img-lazy rank-img circle"
                    data-src="http://image1.daduoduo.com/Goods/202310/469bb8bff00b425fac09cc46af74621d?x-oss-process=image/resize,h_150,w_150"
                    src="http://image1.daduoduo.com/Goods/202310/469bb8bff00b425fac09cc46af74621d?x-oss-process=image/resize,h_150,w_150"
                    lazy="loaded"
                  /></div
              ></a>
              <div class="flex flex-direction-column justify-content-center">
                <a
                  href="/product/promotionDetail?id=3620889142579355421"
                  tag="a"
                  target="_blank"
                  class="fs12 font-weight-400 c333 text-decoration-none link-hover"
                  ><div
                    class="el-tooltip ellipsis-2"
                    aria-describedby="el-tooltip-3920"
                    tabindex="0"
                  >
                    德佑羽绒服去渍湿巾免洗清洁强力去油污免洗去渍湿巾小包装【官旗】
                  </div></a
                >
              </div>
            </div>
            <div class="property-content">
              <div class="property-item mr10">
                <span class="mt5 fs14 font-weight-600 c333 cds-font lh100p">
                  20%
                </span>
              </div>
              <div class="property-item" style="min-width: 90px">
                <div class="mt5 textInline">
                  <span class="fs14 font-weight-600 c333 cds-font lh100p">
                    1,856
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!---->
        <!---->
      </div>
      <div class="flex-1 rank-box relative">
        <div class="rank-title">
          <div
            class="flex align-items-center justify-content-space-between pt15"
          >
            <span class="fs20 font-weight-600 c333">达人涨粉榜</span>
            <div
              class="link-btn-wrapper flex align-items-center cursor-pointer"
            >
              <span class="text fs12 c999 font-weight-400 mr5">完整榜单</span
              ><i class="arrow el-icon-arrow-right"></i>
            </div>
          </div>
          <div class="mt5">
            <span class="fs12 font-weight-600 c999"> 2023-12-11 </span>
          </div>
        </div>
        <div class="rank-list">
          <div class="property-label-content">
            <div class="label-item">粉丝增量</div>
          </div>
          <div class="rank-item">
            <div
              class="rank-index flex align-items-center justify-content-center"
            >
              <img
                src="//oss-image2.quanmamaimg.com/AdminImageUpload/2023/3/1/2415789top1_ORIGIN_zczN.png"
                alt="ddd-img"
                class="rank-index-img"
              />
            </div>
            <div class="flex-1 flex rank-info mr5">
              <a
                href="/author/authorDetail?userId=1288250341195923"
                tag="a"
                target="_blank"
                class="block text-decoration-none mr8"
                ><div>
                  <img
                    alt="ddd-img"
                    class="img-lazy rank-img circle"
                    data-src="http://image1.daduoduo.com/People/202210/b1f79c75c8a149a2827aafb1d226c95b?x-oss-process=image/resize,h_150,w_150"
                    src="http://image1.daduoduo.com/People/202210/b1f79c75c8a149a2827aafb1d226c95b?x-oss-process=image/resize,h_150,w_150"
                    lazy="loaded"
                  /></div
              ></a>
              <div class="flex flex-direction-column justify-content-center">
                <a
                  href="/author/authorDetail?userId=1288250341195923"
                  tag="a"
                  target="_blank"
                  class="info-name text-decoration-none link-hover"
                  ><div
                    class="el-tooltip ellipsis-1"
                    aria-describedby="el-tooltip-6728"
                    tabindex="0"
                  >
                    大号甄选(不开语音直播谨防被骗)
                  </div></a
                ><span class="ellipsis-1 info-property mt5"> 粉丝 763.9w </span>
              </div>
            </div>
            <div class="property-content">
              <div class="property-item">
                <span class="mt5 fs14 font-weight-600 c333 cds-font lh100p">
                  44.2w
                </span>
              </div>
            </div>
          </div>
          <div class="rank-item">
            <div
              class="rank-index flex align-items-center justify-content-center"
            >
              <img
                src="//oss-image2.quanmamaimg.com/AdminImageUpload/2023/3/1/2541297top2_ORIGIN_2kDN.png"
                alt="ddd-img"
                class="rank-index-img"
              />
            </div>
            <div class="flex-1 flex rank-info mr5">
              <a
                href="/author/authorDetail?userId=712920574993533"
                tag="a"
                target="_blank"
                class="block text-decoration-none mr8"
                ><div>
                  <img
                    alt="ddd-img"
                    class="img-lazy rank-img circle"
                    data-src="http://image1.daduoduo.com/People/202306/5788ceeb0b6c4b8baff1db62f20b6844?x-oss-process=image/resize,h_150,w_150"
                    src="http://image1.daduoduo.com/People/202306/5788ceeb0b6c4b8baff1db62f20b6844?x-oss-process=image/resize,h_150,w_150"
                    lazy="loaded"
                  /></div
              ></a>
              <div class="flex flex-direction-column justify-content-center">
                <a
                  href="/author/authorDetail?userId=712920574993533"
                  tag="a"
                  target="_blank"
                  class="info-name text-decoration-none link-hover"
                  ><div
                    class="el-tooltip ellipsis-1"
                    aria-describedby="el-tooltip-1"
                    tabindex="0"
                  >
                    沧海一舟
                  </div></a
                ><span class="ellipsis-1 info-property mt5"> 粉丝 582.4w </span>
              </div>
            </div>
            <div class="property-content">
              <div class="property-item">
                <span class="mt5 fs14 font-weight-600 c333 cds-font lh100p">
                  18.6w
                </span>
              </div>
            </div>
          </div>
          <div class="rank-item">
            <div
              class="rank-index flex align-items-center justify-content-center"
            >
              <img
                src="//oss-image2.quanmamaimg.com/AdminImageUpload/2023/3/1/261466top3_ORIGIN_0gjM.png"
                alt="ddd-img"
                class="rank-index-img"
              />
            </div>
            <div class="flex-1 flex rank-info mr5">
              <a
                href="/author/authorDetail?userId=839672430340844"
                tag="a"
                target="_blank"
                class="block text-decoration-none mr8"
                ><div>
                  <img
                    alt="ddd-img"
                    class="img-lazy rank-img circle"
                    data-src="http://image1.daduoduo.com/People/202307/099782fe70844130ad142c5c7567f8a7?x-oss-process=image/resize,h_150,w_150"
                    src="http://image1.daduoduo.com/People/202307/099782fe70844130ad142c5c7567f8a7?x-oss-process=image/resize,h_150,w_150"
                    lazy="loaded"
                  /></div
              ></a>
              <div class="flex flex-direction-column justify-content-center">
                <a
                  href="/author/authorDetail?userId=839672430340844"
                  tag="a"
                  target="_blank"
                  class="info-name text-decoration-none link-hover"
                  ><div
                    class="el-tooltip ellipsis-1"
                    aria-describedby="el-tooltip-9004"
                    tabindex="0"
                  >
                    搞笑亮哥
                  </div></a
                ><span class="ellipsis-1 info-property mt5"> 粉丝 171.0w </span>
              </div>
            </div>
            <div class="property-content">
              <div class="property-item">
                <span class="mt5 fs14 font-weight-600 c333 cds-font lh100p">
                  18.5w
                </span>
              </div>
            </div>
          </div>
          <div class="rank-item">
            <div
              class="rank-index flex align-items-center justify-content-center"
            >
              <span class="cds-font fs14 font-weight-600 c999"> 4 </span>
            </div>
            <div class="flex-1 flex rank-info mr5">
              <a
                href="/author/authorDetail?userId=2771236108120475"
                tag="a"
                target="_blank"
                class="block text-decoration-none mr8"
                ><div>
                  <img
                    alt="ddd-img"
                    class="img-lazy rank-img circle"
                    data-src="http://image1.daduoduo.com/People/202211/4ce7b36ed0474d9d8b9884063f287857?x-oss-process=image/resize,h_150,w_150"
                    src="http://image1.daduoduo.com/People/202211/4ce7b36ed0474d9d8b9884063f287857?x-oss-process=image/resize,h_150,w_150"
                    lazy="loaded"
                  /></div
              ></a>
              <div class="flex flex-direction-column justify-content-center">
                <a
                  href="/author/authorDetail?userId=2771236108120475"
                  tag="a"
                  target="_blank"
                  class="info-name text-decoration-none link-hover"
                  ><div
                    class="el-tooltip ellipsis-1"
                    aria-describedby="el-tooltip-9123"
                    tabindex="0"
                  >
                    尚将军
                  </div></a
                ><span class="ellipsis-1 info-property mt5"> 粉丝 150.4w </span>
              </div>
            </div>
            <div class="property-content">
              <div class="property-item">
                <span class="mt5 fs14 font-weight-600 c333 cds-font lh100p">
                  18.1w
                </span>
              </div>
            </div>
          </div>
          <div class="rank-item">
            <div
              class="rank-index flex align-items-center justify-content-center"
            >
              <span class="cds-font fs14 font-weight-600 c999"> 5 </span>
            </div>
            <div class="flex-1 flex rank-info mr5">
              <a
                href="/author/authorDetail?userId=2692071286380711"
                tag="a"
                target="_blank"
                class="block text-decoration-none mr8"
                ><div>
                  <img
                    alt="ddd-img"
                    class="img-lazy rank-img circle"
                    data-src="http://image1.daduoduo.com/People/202311/98f7920e38bb4299a2015de588a6e78b?x-oss-process=image/resize,h_150,w_150"
                    src="http://image1.daduoduo.com/People/202311/98f7920e38bb4299a2015de588a6e78b?x-oss-process=image/resize,h_150,w_150"
                    lazy="loaded"
                  /></div
              ></a>
              <div class="flex flex-direction-column justify-content-center">
                <a
                  href="/author/authorDetail?userId=2692071286380711"
                  tag="a"
                  target="_blank"
                  class="info-name text-decoration-none link-hover"
                  ><div
                    class="el-tooltip ellipsis-1"
                    aria-describedby="el-tooltip-6369"
                    tabindex="0"
                  >
                    口罩哥🏅️771🏅️
                  </div></a
                ><span class="ellipsis-1 info-property mt5"> 粉丝 81.1w </span>
              </div>
            </div>
            <div class="property-content">
              <div class="property-item">
                <span class="mt5 fs14 font-weight-600 c333 cds-font lh100p">
                  16.5w
                </span>
              </div>
            </div>
          </div>
        </div>
        <!---->
        <!---->
      </div>
    </div>
  </div>
</template>
<script>
import live from "./live.json";
import retui from "./retui.json";
import zhangfen from "./zhangfen.json";
export default {
  data() {
    return {
      PopularList: [],
      live,
      retui,
      zhangfen,
      badge: [
        "//oss-image2.quanmamaimg.com/AdminImageUpload/2023/3/1/2415789top1_ORIGIN_zczN.png",
        "//oss-image2.quanmamaimg.com/AdminImageUpload/2023/3/1/2541297top2_ORIGIN_2kDN.png",
        "//oss-image2.quanmamaimg.com/AdminImageUpload/2023/3/1/261466top3_ORIGIN_0gjM.png",
      ],
    };
  },
  mounted() {
    console.log("热门直播间数据", live);
  },
  methods: {
    // 定义函数
    // 观看人次格式化
    formatTotalUser(value) {
      if (value >= 10000) {
        return (value / 10000).toFixed(1) + "万";
      } else if (value >= 1000) {
        return (value / 1000).toFixed(1) + "千";
      } else {
        return value;
      }
    },
  },
};
</script>

<style scoped lang="less">
/* scoped 让style样式 局部生效*/
@import "@/assets/css/dashboard.css";
@import "@/assets/css/base.css";
@import "@/assets/css/ddd-common.css";
@import "@/assets/css/ddd-common-components.css";
</style>
