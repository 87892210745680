<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="mb-[16px]">
    <!-- 搜索框 -->
    <div class="w-full">
      <Serach />
    </div>
    <div class="w-[1200px] m-auto">
        <!-- 实时热播 -->
    <broadcast></broadcast>
    <br />
    <!-- 直播电商流量指数 -->
    <Flow></Flow>
    <!-- 热门推荐 -->
    <Popular></Popular>
    <!-- 品类指数大盘 -->
    <grail></grail>
    <!-- 我的收藏 -->
    <Collection></Collection>
    </div>

  </div>
</template>
<script>
import Collection from "@/components/zujian/index/Collection.vue";
import grail from "@/components/zujian/index/grail.vue";
import Popular from "@/components/zujian/index/Popular.vue";
import Flow from "@/components/zujian/index/Flow.vue";
import broadcast from "@/components/zujian/index/broadcast.vue";
import Serach from "@/components/zujian/index/Search.vue";
export default {
  data() {
    return {
      indexList: [],
    };
  },
  methods: {
    // 定义函数
  },
  components: {
    Collection,
    grail,
    Popular,
    Flow,
    broadcast,
    Serach,
  },
};
</script>

<style scoped>
/* scoped 让style样式 局部生效*/
</style>
