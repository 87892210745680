import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import "./plugins/element.js";
import store from "./store";
import router from "./router";
import "swiper/css/swiper.css";
Vue.config.productionTip = false;
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VXETable from 'vxe-table'
import "vxe-table/lib/style.css";
import "./styles/tailwindcss.css";
import 'bootstrap/dist/css/bootstrap.css'
// Vue.use(ElementUI);
function useTable(app) {
  app.use(VXETable)
}
let a={
  title:'标题',
  tip:'提示信息',
  value:''
}
Vue.use(ElementUI, { size: "small", zIndex: 3000 });
Vue.use(useTable);
new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
