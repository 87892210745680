import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    Config: {
      Api_Url: 'https://www.daduoduo.com/',//请求接口
      Api_Prefix: '/ajax',//请求前缀
    }
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
