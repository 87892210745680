<!-- eslint-disable itemvue/multi-word-component-names -->
<template>
  <div class="connect">
    <div class="title">
      <span>实时热播</span>
    </div>
    <div class="main">
      <div class="swiper-container">
        <div class="swiper-wrapper list">
          <div
            class="swiper-slide item"
            v-for="(item, index) in jsondata"
            :key="index"
            style="width: 235px;"
          >
            <!-- 图片 -->
            <!-- 这个滚动 滚不起来 -->
            <div class="image relative">
              <img :src="formattrurl(item.RoomPic)" alt="" />
              <div class="on-live absolute left-[15px] top-[20px] w-[84px] h-[26px] rounded-[15px] bg-[#fd4c60] leading-[26px] text-white text-[14px] text-center flex-center">直播中<img class="max-h-[12px] max-w-[12px] h-[12px] bg-cover object-cover" src="@/assets/icon/living.gif" alt=""></div>
            </div>
            <div class="item-title">
              <img :src="item.RoomPic" alt="" />
              <div class="room-name">{{ item.LiveName }}</div>
            </div>
            <div class="sale-info">
              <!-- 销售信息 -->
              <div class="left">销售额</div>
              <div class="right">{{ item.SaleCnt }}</div>
            </div>
          </div>
        </div>

        <!-- <div class="swiper-button-next"></div> -->
        <!-- <div class="swiper-button-prev"></div> -->
        <!-- <div class="swiper-pagination"></div> -->
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper";
import jsondata from "./broadcast.json";
// Import Swiper styles
// 实时热播
export default {
  components: {},
  data() {
    return {
      jsondata,
    };
  },
  mounted() {
    new Swiper(".swiper-container", {
      loop: true,
      mousewheel: true,
      keyboard: true,
      slidesPerView: 5,
      slidesOffsetBefore: 235,
      slidesPerGroup: 1,
      autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
      },

      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },

      pagination: {
        el: ".swiper-pagination",
      },
    });
  },
  methods: {
    // 定义函数
    formattrurl(url) {
      return url.replace(/resize,h_150,w_150/, "resize,h_600,w_600");
    },
  },
};
</script>

<style scoped lang="less">
/* scoped 让style样式 局部生效*/
.swiper {
  height: 275px;
}
.connect {
  padding: 20px;
  width: 1200px;
  height: 358px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 3px 15px 0 hsla(0, 0%, 48.2%, 0.1);
  .title {
    // 标题
    font-weight: 600;
    font-size: 16px;
    padding-bottom: 15px;
    span {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-weight: 600;
      font-size: 20px;
      color: #333;
    }
  }
  .main {
    width: 1170px;
    height: 275px;
    border-radius: 10px;
    // background-color: red;
    .list {
      display: flex;
      gap: 15px;
      .item {
        border-radius: 15px;
        width: 220px !important;
        height: 275px;
        background-color: #f3f3f3;
        .image {
          border-radius: 15px;
          width: 220px;
          height: 220px;
          img {
            border-radius: 15px;
            width: 100%;
            height: 100%;
          }
        }
        .item-title {
          margin-top: -40px;
          z-index: 2;
          background: #0000008c;
          opacity: 0.7;
          display: flex;
          width: 220px;
          height: 40px;
          img {
            margin: 0 10px;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            align-items: center;
            opacity: 1;
          }
          .room-name {
            // 文本靠左 上下居中
            text-align: left;
            padding-top: 10px;
            font-size: 14px;
            list-style: none;
            cursor: pointer;
            margin: 0;
            padding: 0;
            box-sizing: border-box;
            -webkit-font-smoothing: antialiased;
            width: 150px;
            height: 40px;
            line-height: 40px;
            color: #fff;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        .sale-info {
          font-size: 14px;
          list-style: none;
          cursor: pointer;
          margin: 0;
          box-sizing: border-box;
          -webkit-font-smoothing: antialiased;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #f5f5f5;
          padding: 15px;
          border-radius: 0 0 15px 15px;
          .left {
            // 销售额
            font-size: 14px;
            list-style: none;
            cursor: pointer;
            margin: 0;
            padding: 0;
            box-sizing: border-box;
            -webkit-font-smoothing: antialiased;
            color: #999;
          }
          .right {
            // 500~700W
            font-size: 14px;
            list-style: none;
            cursor: pointer;
            margin: 0;
            padding: 0;
            box-sizing: border-box;
            -webkit-font-smoothing: antialiased;
            color: #333;
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>
