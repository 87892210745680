<template>
  <div class="home">
    <el-container direction="vertical">
      <el-header height="70px">
        <div
          class="header min-w-[1200px] justify-between m-auto flex items-center h-[70px]"
        >
          <div class="flex items-center w-[213px] h-[70px]">
            <router-link to="/">
              <img
                class="w-[203px] h-[33px] mr-[10px]"
                alt="达多多logo"
                :src="imgurl"
              />
            </router-link>
          </div>
          <el-menu
            text-color="#333"
            active-text-color="#f00"
            :default-active="activeIndex"
            router
            class="flex-1 ml-[30px] h-full text-[14px]"
            mode="horizontal"
            @select="handleSelect"
          >
            <el-submenu
              :index="items.index"
              v-for="(items, indexs) in menulist"
              :key="items.url"
            >
              <template slot="title">
                <router-link class="routelink" :to="`${items.url}`">{{
                  items.title
                }}</router-link>
              </template>
              <el-menu-item
                :style="{ height: item.desc ? '50px' : '25px' }"
                v-if="items.children"
                :index="item.url"
                v-for="(item, index) in items.children"
                :disabled="item.disabled ?? false"
              >
                <template slot="title">
                  <div class="sub-menu-item" v-if="item.icon">
                    <div class="flex align-items-center">
                      <img
                        :style="{ display: item.icon ? '' : 'none' }"
                        :src="item.icon"
                        alt="ddd-img"
                        class="left-icon"
                      />
                      <div class="title">
                        <router-link class="routelink" :to="`${item.url}`">
                          {{ item.title }}</router-link
                        >
                      </div>
                      <!-- <router-link style="display: block;" class="title routelink" :to="item.url">{{ item.title
                      }}</router-link> -->
                    </div>
                    <div class="flex align-items-center" disabled>
                      <div
                        class="left-icon"
                        style="width: 20px; height: 20px; margin-right: 8px"
                      ></div>
                      <div
                        class="sub-title"
                        style="
                          font-size: 12px;
                          font-weight: 400;
                          color: #adadad;
                        "
                      >
                        {{ item.desc }}
                      </div>
                    </div>
                  </div>
                  <div class="sub-menu-item flex align-items-center" v-else>
                    <div class="left-icon"></div>
                    <a class="title routelink" :href="item.url">
                      <div class="title">{{ item.title }}</div>
                      <div class="sub-title">{{ item.desc }}</div>
                    </a>
                  </div>
                </template>
              </el-menu-item>
            </el-submenu>
          </el-menu>

          <el-popover placement="bottom" width="100" trigger="hover">
            <div
              style="
                text-align: center;
                display: flex;
                flex-wrap: wrap;
                padding: 10px;
              "
            >
              <img
                class="w-[110px] h-[110px] m-auto"
                src="//oss-image3.quanmamaimg.com/AdminImageUpload/2022/11/23/43199gmzx_ORIGIN_3kzM.png"
                alt=""
              />
              <span
                style="
                  color: #606266;
                  line-height: 1.4;
                  font-size: 14px;
                  text-align: center;
                  margin: 0 auto;
                "
                class="desc"
                >添加服务顾问领取</span
              >
            </div>
            <div class="w-[100px] flex-center" slot="reference">
              <img
                class="w-[24px] h-[24px] mr-[3px]"
                src="https://oss-image3.quanmamaimg.com/AdminImageUpload/2023/6/7/5236655%E4%BC%81%E4%B8%9A%E5%BE%AE%E4%BF%A101_ORIGIN_zkTN.png"
                alt=""
              />
              <span class="font-[600] text-[14px] text-[#333]">免费领会员</span>
            </div>
          </el-popover>
          <el-popover placement="bottom" width="100" trigger="hover">
            <div
              style="
                text-align: center;
                display: flex;
                flex-wrap: wrap;
                padding: 10px;
              "
            >
              <img
                class="w-[110px] h-[110px] m-auto"
                src="//oss-image3.quanmamaimg.com/AdminImageUpload/2022/11/3/529648daduoduo_miniprogram_ORIGIN_xADO.jpg"
                alt=""
              />
              <span
                style="
                  color: #606266;
                  line-height: 1.4;
                  font-size: 14px;
                  text-align: center;
                  margin: 0 auto;
                "
                class="desc"
                >微信扫码打开</span
              >
            </div>
            <div class="w-[100px] flex-center pr-[10px]" slot="reference">
              <img
                class="w-[24px] h-[24px] mr-[3px]"
                src="https://oss-image3.quanmamaimg.com/AdminImageUpload/2022/11/11/1750240xcx1_ORIGIN_wIDM.png"
                alt=""
              />
              <span class="font-[600] text-[14px] text-[#333]">小程序</span>
            </div>
          </el-popover>
          <div
            class="w-[100px] min-w-[100px] flex-center h-[32px] leading-[32px] inline-block text bg-[#fd4c60] rounded-[22px] cursor-pointer text-center"
          >
            <span class="text-[14px] text-white font-[600]">注册/登录</span>
          </div>
        </div>
      </el-header>
      <div class="main-frame">
        <div class="flow-market-page">
          <div class="text-[14px] p-0 box-border m-auto relative">
            <router-view></router-view>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </el-container>
  </div>
</template>
<script>
import Footer from "@/components/zujian/index/Footer.vue";
// eslint-disable-next-line
import Serach from "@/components/zujian/index/Search.vue";
export default {
  name: "HomeView",
  data() {
    return {
      path: "",
      activeIndex: "0",
      index: true,
      imgurl: require("@/assets/logo.png"),
      mouseIndex: "",
      menulist: [
        {
          title: "首页",
          url: "/",
          index: "",
          children: [],
        },
        {
          title: "达人",
          url: "/talent/index",
          index: "talent",
          children: [
            {
              url: "/talent/index",
              icon: "//oss-image2.quanmamaimg.com/AdminImageUpload/2023/3/1/3243453author_ORIGIN_wYTN.png",
              title: "达人库",
              desc: "搜索发现全网达人",
            },
            {
              icon: "//oss-image3.quanmamaimg.com/AdminImageUpload/2023/3/1/3744376toprank_ORIGIN_wMDO.png",
              title: "达人榜单",
            },
            {
              url: "/talent/list/1",
              title: "达人带货榜",
              desc: "找带货效果最好的达人",
            },
            {
              url: "/talent/list/2",
              title: "涨粉达人榜",
              desc: "找涨粉最多的达人",
            },
            {
              url: "/talent/list/3",
              title: "行业达人榜",
              desc: "找各行业头部达人",
            },
            {
              url: "/talent/list/4",
              title: "地区达人榜",
              desc: "找本地头部达人",
            },
            {
              url: "/talent/list/5",
              title: "黑马达人榜",
              desc: "找优质潜力达人",
            },
            {
              url: "/talent/list/6",
              title: "点赞达人榜",
              desc: "找获赞最高的达人",
            },
            {
              url: "/talent/list/7",
              title: "MCN机构榜",
              desc: "找快速增长的MCN",
            },
          ],
        },
        {
          title: "商品",
          url: "/goods/index",
          index: "goods",
          children: [
            {
              url: "/goods/index",
              icon: "//oss-image2.quanmamaimg.com/AdminImageUpload/2023/3/1/27262product2_ORIGIN_1QjN.png",
              title: "商品库",
              desc: "搜索发现抖音商品",
            },
            {
              icon: "//oss-image3.quanmamaimg.com/AdminImageUpload/2023/3/1/3744376toprank_ORIGIN_wMDO.png",
              title: "商品榜单",
            },
            {
              url: "/goods/list/1",
              title: "抖音热推榜",
              desc: "找推广人数最多的商品",
            },
            {
              url: "/goods/list/2",
              title: "抖音销量榜",
              desc: "找抖音销量最高的商品",
            },
            {
              url: "/goods/list/3",
              title: "实时销量榜",
              desc: "近2小时热销爆品",
            },
            {
              url: "/goods/list/4",
              title: "直播商品榜",
              desc: "找直播出单最多的商品",
            },
            {
              url: "/goods/list/5",
              title: "潜力爆品榜",
              desc: "找近期高销量潜力爆品",
            },
          ],
        },
        {
          title: "直播",
          url: "/live/index",
          index: "live",
          children: [
            {
              url: "/live/index",
              icon: "//oss-image3.quanmamaimg.com/AdminImageUpload/2023/3/1/243880live2_ORIGIN_zETM.png",
              title: "直播库",
              desc: "搜索发现抖音直播间",
            },
            {
              icon: "//oss-image3.quanmamaimg.com/AdminImageUpload/2023/3/1/3744376toprank_ORIGIN_wMDO.png",
              title: "直播榜单",
            },
            {
              url: "/live/list/1",
              title: "今日带货榜",
              desc: "发现今天带货最爆的直播",
            },
            {
              url: "/live/list/2",
              title: "带货小时榜",
              desc: "找各个时段头部直播间",
            },
            {
              url: "/live/list/3",
              title: "官方人气榜",
              desc: "找最热门的在播直播间",
            },
            {
              url: "/live/list/4",
              title: "官方小时榜",
              desc: "找在线人数最高的直播间",
            },
          ],
        },
        {
          title: "视频",
          url: "/video/index",
          index: "video",
          children: [
            {
              icon: "//oss-image2.quanmamaimg.com/AdminImageUpload/2023/3/1/1618791awemep_ORIGIN_0kTO.png",
              title: "带货视频库",
              desc: "搜索发现抖音带货视频",
              url: "/video/index",
            },
            {
              url: "/video/Bring",
              icon: "//oss-image2.quanmamaimg.com/AdminImageUpload/2023/3/1/372037%E8%A7%86%E9%A2%91_ORIGIN_yITO.png",
              title: "热门视频库",
              desc: "搜索发现抖音热门视频",
            },
            {
              icon: "//oss-image3.quanmamaimg.com/AdminImageUpload/2023/3/1/3744376toprank_ORIGIN_wMDO.png",
              title: "视频榜单",
            },
            {
              url: "/video/list/1",
              title: "带货视频榜",
              desc: "找带货效果最好的视频",
            },
            {
              url: "/video/list/2",
              title: "图文带货榜",
              desc: "找带货效果最好的图文",
            },
            {
              url: "/video/list/3",
              title: "热门视频榜",
              desc: "找点赞最高的短视频",
            },
          ],
        },
        {
          title: "小店",
          url: "/shop/index",
          index: "shop",
          children: [
            {
              url: "/shop/index",
              icon: "//oss-image3.quanmamaimg.com/AdminImageUpload/2023/3/1/277192shop1_ORIGIN_1MzN.png",
              title: "小店库",
              desc: "搜索发现抖音小店",
            },
            {
              icon: "//oss-image3.quanmamaimg.com/AdminImageUpload/2023/3/1/3744376toprank_ORIGIN_wMDO.png",
              title: "小店榜单",
            },
            {
              url: "/shop/list/1",
              title: "抖音小店榜",
              desc: "找销售额最高的小店",
            },
            {
              url: "/shop/list/2",
              title: "品牌官方小店榜",
              desc: "找销售额最高的品牌官方小店",
            },
          ],
        },
        {
          title: "品牌",
          url: "/brand/index",
          index: "pinpai",
          children: [
            {
              url: "/brand/index",
              icon: "//oss-image1.quanmamaimg.com/AdminImageUpload/2023/3/1/2722128brand_ORIGIN_zAjM.png",
              title: "品牌库",
              desc: "搜索发现抖音带货品牌",
            },
            {
              icon: "//oss-image3.quanmamaimg.com/AdminImageUpload/2023/3/1/3744376toprank_ORIGIN_wMDO.png",
              title: "品牌榜单",
            },
            {
              url: "/brand/list/1",
              title: "热门品牌榜",
              desc: "找销量最高的品牌",
            },
            {
              url: "/brand/list/2",
              title: "品牌自播榜",
              desc: "找销售额最高的自播品牌",
            },
            {
              url: "/brand/list/3",
              title: "高增长品牌榜",
              desc: "找行业高增长品牌",
            },
            {
              url: "/brand/list/4",
              title: "商品卡热销品牌榜",
              desc: "找行业商品卡热销品牌",
            },
          ],
        },
        {
          disabled: true,
          title: "工具",
          url: "/tools",
          index: "gongju",
          children: [
            {
              icon: "https://oss-image1.quanmamaimg.com/AdminImageUpload/2023/12/6/3058628tool_ORIGIN_5UDM.png",
              title: "工具",
            },
            {
              title: "抖音号管理",
              desc: "管理抖音号授权",
            },
            {
              title: "达人订单宝",
              desc: "收益概览、带货明细",
            },
            {
              title: "橱窗商品管理",
              desc: "一键提升橱窗商品佣金",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.handleRouteChange(this.$route);
    this.index = this.$route.fullPath === "/";
    // 监听路由变化
    // this.$router.afterEach((to, from) => {
    //   this.path = to.name;
    //   if (to.name == "index") {
    //     this.index = true;
    //   } else {
    //     this.index = false;
    //   }
    //   this.handleRouteChange(to);
    // });
  },
  // 深度监听当前路由
  methods: {
    handleRouteChange(route) {
      // 获取当前路由路径
      const currentRoute = route.path.split("/")[1];
    },
    handleSelect(key, keyPath) {
      // 跳转路由
      // this.$router.push('/');
      this.activeIndex = key;
      // if (key == this.$route.fullPath) {
      //   console.log('不需要跳转页面');
      //   location.reload()
      // } else {
      //   console.log('需要跳转页面到', key);
      //   // 从key里面取出type的值
      // }
    },
  },
  components: {
    Footer,
    Serach,
  },
};
</script>
<style scoped lang="less">
.el-menu-item {
  // height: 50px !important;

  .sub-menu-item {
    line-height: 20px;
    padding: 8px;
    vertical-align: middle;

    .sub-title {
      font-size: 12px;
      font-weight: 400;
      color: #adadad;
    }

    .title:hover {
      color: red;
    }

    .title {
      font-size: 14px;
      font-weight: 600;
      color: #333;
      transition: all 0.2s;
    }

    .left-icon {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }
}

.el-footer {
  padding: 0 !important;
}

.routelink {
  // 去掉下划线
  text-decoration: none;
  // 浅灰色
  color: #606266;
  // line-height: 70px;
}

.home {
  padding-top: 70px;

  .main-frame {
    font-size: 14px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: SF Pro SC, SF Pro Text, SF Pro Icons, PingFang SC,
      Helvetica Neue, Helvetica, Arial, sans-serif;

    .flow-market-page {
      font-size: 14px;
      margin: 0;
      margin-top: -1px;
      padding: 0;
      box-sizing: border-box;
      position: relative;
      background-color: #fafafa;

      .content {
        font-size: 14px;
        padding: 0;
        box-sizing: border-box;
        font-family: SF Pro SC, SF Pro Text, SF Pro Icons, PingFang SC,
          Helvetica Neue, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        position: relative;
        width: 1200px;
        margin: 0 auto;
      }
    }
  }
}

// .is-active-menu {
//   font-weight: 700;
//   color: #333 !important;
//   cursor: default !important;

//   &::after {
//     content: "";
//     width: 28px;
//     height: 5px;
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     background-color: #fd4c60;
//     // opacity: 0;
//     border-radius: 3px;
//     transition: opacity 0.2s;
//     z-index: 1;
//     cursor: default;
//   }
// }

.group-class:hover .group-item-class {
  color: #fd4c60;
}
</style>
<style>
body {
  height: 100%;
}

.el-header {
  position: fixed;
  top: 0;
  z-index: 999;
  background: #fff;
  width: 100%;
  padding: 0 30px !important;
}

.el-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
}

.main-frame {
  flex: 1;
}

/* 
.el-submenu__title {
  padding: 0 !important;
  padding-right: 25px !important;
  cursor: default !important;
} */

.el-icon-arrow-down {
  margin-left: 0 !important;
  font-size: 8px !important;
  color: #666 !important;
}

.b-b-active::after {
  content: "";
  width: 28px;
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: auto;
  background-color: #fd4c60;
  border-radius: 3px;
  transition: opacity 0.2s;
  z-index: 9999;
  cursor: default;
}

/* .menu-active {
  color: #333;
  font-weight: 700;
} */

.popverClass {
  transition: none !important;
  transform: translateX(-40%);
  box-sizing: border-box;
  width: 240px;
  top: 60px !important;
  position: absolute;
}

.bor-t {
  position: relative;
}

.bor-t::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 0 16px;
  height: 1px;
  background-color: #f1f1f1;
}

.bor-b {
  position: relative;
}

.bor-b::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 0 16px;
  height: 1px;
  background-color: #f1f1f1;
}
</style>
