<template>
  <div id="app">
    <HelloWorld msg="Welcome to Your Vue.js App" />
  </div>
</template>

<script>
import HelloWorld from "@/views/HomeView.vue";
export default {
  name: "app",
  components: {
    HelloWorld,
  },
};
</script>

<style>
@import url(@/assets/icon/iconfont.css);
#app {
  width: 100%;
  height: 100%;
  font-family: SF Pro SC, SF Pro Text, SF Pro Icons, PingFang SC, Helvetica Neue,
    Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  text-align: center;
  color: #2c3e50;
  background-color: #fafafa;
  /* margin-top: 60px; */
}
.headerCellClassName {
  background-color: rgb(231, 236, 241) !important;
  color: rgb(130, 142, 154) !important;
  font-weight: 400 !important;
  padding: 4px 0px !important;
  font-size: 13px !important;
  height: 32.8px !important;
}
.vxe-header--row {
  height: 32.8px !important;
}
</style>
