<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="home-footer-war flex align-items-center justify-content-center">
    <div class="home-footer-box">
      <div class="footer-top footer-container flex">
        <div class="footer-links-box">
          <div class="footer-links flex">
            <div class="footer-links-item cmm">
              <h3>达多多</h3>
              <ul>
                <li>
                  <a
                    href="/about"
                    tag="a"
                    target="_blank"
                    class="cursor-pointer link"
                    >关于我们</a
                  >
                </li>
                <li>
                  <a
                    href="/userAgreement"
                    tag="a"
                    target="_blank"
                    class="cursor-pointer link"
                    >服务协议</a
                  >
                </li>
                <li>
                  <a
                    href="/relief"
                    tag="a"
                    target="_blank"
                    class="cursor-pointer link"
                    >免责声明</a
                  >
                </li>
              </ul>
            </div>
            <div class="footer-links-item contact">
              <h3 class="text-left">联系我们</h3>
              <div class="flex flex-flow-row-wrap">
                <ul>
                  <li class="flex align-items-center">
                    <div class="link no">购买咨询</div>
                    <div
                      class="wetchat-code-war flex align-items-center justify-content-center ml10 cursor-pointer relative"
                    >
                      <i class="wetchat-code"></i>
                      <div
                        class="code-img flex align-items-center justify-content-center"
                      >
                        <img
                          src="//oss-image3.quanmamaimg.com/AdminImageUpload/2022/11/23/43199gmzx_ORIGIN_3kzM.png"
                          alt="ddd-img"
                          class="pt5 pb5 pl5 pr5"
                        />
                      </div>
                    </div>
                  </li>
                  <li class="flex align-items-center">
                    <div class="link no">商务合作</div>
                    <div
                      class="wetchat-code-war flex align-items-center justify-content-center ml10 cursor-pointer relative"
                    >
                      <i class="wetchat-code"></i>
                      <div
                        class="code-img flex align-items-center justify-content-center"
                      >
                        <img
                          src="//oss-image3.quanmamaimg.com/AdminImageUpload/2022/11/23/4339296swhz_ORIGIN_3IjM.png"
                          alt="ddd-img"
                          class="pt5 pb5 pl5 pr5"
                        />
                      </div>
                    </div>
                  </li>
                  <li class="flex align-items-center">
                    <div class="link no">产品反馈</div>
                    <div
                      class="wetchat-code-war flex align-items-center justify-content-center ml10 cursor-pointer relative"
                    >
                      <i class="wetchat-code"></i>
                      <div
                        class="code-img flex align-items-center justify-content-center"
                      >
                        <img
                          src="//oss-image3.quanmamaimg.com/AdminImageUpload/2022/11/23/423939cpfk_ORIGIN_yATM.png"
                          alt="ddd-img"
                          class="pt5 pb5 pl5 pr5"
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="footer-links-item product">
              <h3 class="text-left">微信小程序</h3>
              <ul>
                <li class="flex align-items-center">
                  <div class="link no">达多多数据</div>
                  <div
                    class="wetchat-code-war flex align-items-center justify-content-center ml10 cursor-pointer relative"
                  >
                    <i class="wetchat-code"></i>
                    <div
                      class="code-img flex align-items-center justify-content-center"
                    >
                      <img
                        src="//oss-image3.quanmamaimg.com/AdminImageUpload/2022/11/3/529648daduoduo_miniprogram_ORIGIN_xADO.jpg"
                        alt="ddd-img"
                        class="pt5 pb5 pl5 pr5"
                        style="padding: 0px"
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="flex-1">
          <div
            class="footer-qrcode flex align-items-center justify-content-center"
          >
            <div class="flex-1"></div>
            <div class="qrcode-box text-align-center mr60">
              <div
                class="img-box flex align-items-center justify-content-center mb10"
              >
                <img
                  src="//oss-image3.quanmamaimg.com/AdminImageUpload/2022/11/3/529648daduoduo_miniprogram_ORIGIN_xADO.jpg"
                  alt="ddd-img"
                />
              </div>
              <p>达多多小程序</p>
            </div>
            <div class="qrcode-box qrcode-box1 text-align-center">
              <div
                class="img-box flex align-items-center justify-content-center mb10"
              >
                <img
                  src="//oss-image3.quanmamaimg.com/AdminImageUpload/2022/11/23/43199gmzx_ORIGIN_3kzM.png"
                  alt="ddd-img"
                />
              </div>
              <p>会员购买咨询</p>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom footer-container">
        <div
          class="footer-copyright flex align-items-center justify-content-center"
        >
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=34019202002109"
            class="text-decoration-none fs12 flex align-items-center mr5"
            style="color: rgba(255, 255, 255, 0.4)"
            ><img
              width="15"
              height="15"
              src="//oss-image1.quanmamaimg.com/AdminImageUpload/202362616019475_ORIGIN_1UjM.jpg"
              alt=""
              class="mr5"
            />
            <span>皖公网安备 34019202002109号</span></a
          >
          <a
            target="_blank"
            href="https://beian.miit.gov.cn"
            class="text-decoration-none cursor-pointer mr5"
            style="color: rgba(255, 255, 255, 0.4); font-size: 12px"
            >皖ICP备18025558号-20</a
          ><span> © 2021-2023 </span
          ><a
            href="/"
            class="text-decoration-none cursor-pointer router-link-active"
            style="color: rgba(255, 255, 255, 0.4); font-size: 12px"
            >达多多</a
          ><span> ，合肥宸业信息科技有限公司，All Rights Reserved. </span>
        </div>
        <div class="footer-copyright">
          数据通过公开渠道获取，达多多进行统计分析，提供抖音数据分析查询服务
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {
      footerList: [],
    };
  },
  methods: {
    // 定义函数
  },
};
</script>

<style lang="less" scoped>
/* scoped 让style样式 局部生效*/
// @import '@/assets/css/dashboard.css';
// @import '@/assets/css/base.css';
// @import '@/assets/css/ddd-common.css';
// @import '@/assets/css/ddd-common-components.css';
</style>
