<template>
  <div
    style="
      position: relative;
      width: 700px;
      height: 326px;
      padding: 0px;
      margin: 0px;
      border-width: 0px;
      cursor: pointer;
    "
  >
    <div id="chart" class="echarts"></div>
  </div>
</template>
<script>
import * as echarts from "echarts";
export default {
  name: "Exponent",
  data() {
    return {
      diskData: [
        {
          name: "服饰内衣",
          value: 156.8,
        },
        {
          name: "日用百货",
          value: 38.7,
        },
        {
          name: "鞋靴箱包",
          value: 37.2,
        },
        {
          name: "珠宝饰品",
          value: 36.1,
        },
        {
          name: "食品饮料",
          value: 30.8,
        },
        {
          name: "家居建材",
          value: 27.8,
        },
        {
          name: "家居家纺",
          value: 27.6,
        },
        {
          name: "礼品文创",
          value: 21.6,
        },
        {
          name: "运动户外",
          value: 19.4,
        },
        {
          name: "美妆护肤",
          value: 16.3,
        },
      ],
    };
  },
  methods: {
    initChart() {
      const chart = echarts.init(document.getElementById("chart"));
      const formatUtil = echarts.format;
      const option = {
        grid: {
          left: "",
          right: "0",
          top: "0",
          bottom: "0",
        },
        legend: {
          show: false,
          data: [], // 禁用图例中的标记点
        },
        series: [
          {
            type: "treemap",
            visibleMin: 300,
            width: 650,
            height: 302.74,
            squareRatio: 2.147,
            breadcrumb: {
              // 隐藏底部显示
              show: false,
            },
            // 是否允许滚轮缩放
            roam: false,
            nodeClick: undefined,
            universalTransition: true,
            label: {
              show: true,
              formatter: "{b}\n{c}",
              borderRadius: 4,
            },
            itemStyle: {
              gapWidth: 2,
              borderColor: "#fff",
            },
            data: this.diskData,
          },
        ],
      };
      chart.setOption(option);
    },
  },
  mounted() {
    const chartElement = document.getElementById("chart");
    if (chartElement) {
      this.initChart();
    } else {
      console.error("Chart element not found");
    }
    // this.initChart();
  },
};
</script>
<style scoped lang="less">
.echarts {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 700px;
  height: 326px;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  padding: 0px;
  margin: 0px;
  border-width: 0px;
}
</style>
