<!-- eslint-disable vue/multi-word-component-names -->
<style scoped>
.el-radio-button__inner {
  background-color: transparent !important;
}
.el-radio-button__inner:hover {
  color: #fd4c60;
}
.el-radio-button.is-active .el-radio-button__inner {
  border: 1px solid #fd4c60;
  box-shadow: none;
}
</style>
<template>
  <div class="trace-collect-content-wrapper mt15">
    <!---->
    <!---->
    <div class="collect-content-wrapper pt10">
      <div class="title-content flex align-items-center pt10 pb10 pl20 pr20">
        <div class="text-align-center" style="width: 100%">
          <span class="fs20 c333 font-weight-600">我的收藏</span>
        </div>
        <div
          class="link-btn-wrapper flex align-items-center cursor-pointer"
          style="position: absolute; right: 20px"
        >
          <span class="text fs12 c999 font-weight-400 mr5">更多收藏</span
          ><i class="arrow el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="mb20 flex-1 relative flex justify-start">
        <div
          role="radiogroup"
          class="el-radio-group ui-el-radio-group-1 select-group pl20"
        >
          <label
            role="radio"
            aria-checked="true"
            tabindex="0"
            class="el-radio-button el-radio-button--mini is-active"
            ><input
              type="radio"
              tabindex="-1"
              autocomplete="off"
              class="el-radio-button__orig-radio"
              value="1"
            /><span class="el-radio-button__inner">
              达人收藏
              <!----></span
            ></label
          ><label role="radio" tabindex="-1" class="el-radio-button el-radio-button--mini"
            ><input
              type="radio"
              tabindex="-1"
              autocomplete="off"
              class="el-radio-button__orig-radio"
              value="2"
            /><span class="el-radio-button__inner">
              商品收藏
              <!----></span
            ></label
          ><label role="radio" tabindex="-1" class="el-radio-button el-radio-button--mini"
            ><input
              type="radio"
              tabindex="-1"
              autocomplete="off"
              class="el-radio-button__orig-radio"
              value="3"
            /><span class="el-radio-button__inner">
              视频收藏
              <!----></span
            ></label
          ><label role="radio" tabindex="-1" class="el-radio-button el-radio-button--mini"
            ><input
              type="radio"
              tabindex="-1"
              autocomplete="off"
              class="el-radio-button__orig-radio"
              value="4"
            /><span class="el-radio-button__inner">
              小店收藏
              <!----></span
            ></label
          ><label role="radio" tabindex="-1" class="el-radio-button el-radio-button--mini"
            ><input
              type="radio"
              tabindex="-1"
              autocomplete="off"
              class="el-radio-button__orig-radio"
              value="5"
            /><span class="el-radio-button__inner">
              品牌收藏
              <!----></span
            ></label
          >
        </div>
      </div>
      <div class="author-collect-wrapper pl20 pr20 pb10">
        <!---->
        <div class="collect-list flex flex-flow-row-wrap">
          <div class="list-item pl20 pr20 cursor-pointer">
            <div class="author-info flex align-items-center relative">
              <a
                href="/author/authorDetail?userId=104934349824"
                tag="a"
                target="_blank"
                class="relative author-icon block text-decoration-none mr10 isLive"
                ><img
                  alt="ddd-img"
                  class="img-lazy author-icon"
                  style="width: 50px; height: 50px"
                  data-src="http://image1.daduoduo.com/People/202211/f9339dc8642c4f0091c1ad728a78d1d0?x-oss-process=image/resize,h_150,w_150"
                  src="http://image1.daduoduo.com/People/202211/f9339dc8642c4f0091c1ad728a78d1d0?x-oss-process=image/resize,h_150,w_150"
                  lazy="loaded" />
                <div class="isLiveLabel">
                  <img
                    src="//oss-image2.quanmamaimg.com/AdminImageUpload/2022/10/19/2324680living_ORIGIN_1EDM.gif"
                    alt="ddd-img"
                    class="icon"
                  />
                  <img
                    src="//oss-image3.quanmamaimg.com/AdminImageUpload/2022/10/12/520851_ORIGIN_1UjN.png"
                    alt="ddd-img"
                    class="text"
                  /></div
              ></a>
              <div class="flex-1 flex flex-direction-column justify-content-center">
                <div class="flex align-items-center">
                  <a
                    href="/author/authorDetail?userId=104934349824"
                    tag="a"
                    target="_blank"
                    class="flex align-items-center relative text-decoration-none ellipsis-1 author-hover c333"
                    ><span class="nickname fs14 c333 font-weight-600 ellipsis-1"
                      >董先生-10号上午11点大型超市万人团购</span
                    ></a
                  >
                  <div class="info-menu-wrapper">
                    <div class="icon-menu-wrapper">
                      <img
                        src="//oss-image1.quanmamaimg.com/AdminImageUpload/2022101218541228_ORIGIN_yYDM.jpg"
                        alt="ddd-img"
                        class="active"
                      />
                      <img
                        src="//oss-image1.quanmamaimg.com/AdminImageUpload/2022101218613792_ORIGIN_2IzM.jpg"
                        alt="ddd-img"
                        class="disabled"
                      />
                    </div>
                    <div class="menu-wrapper">
                      <div class="menu-content collect_loading_104934349824">
                        <div class="menu-item">备注</div>
                        <div class="menu-item">分组</div>
                        <div class="menu-item">收藏</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="unique c999 fs12 ellipsis-1">账号 dongxianshengzb</div>
                <div class="flex align-items-center ellipsis-1" style="margin-top: 2px">
                  <span class="fs12 font-weight-400 cFD4C60">粉丝 3,061.9w</span>
                  <span class="fs12 font-weight-400 cFD4C60">（昨天+4.4w）</span>
                </div>
              </div>
            </div>
            <div class="flex flex-direction-column">
              <div class="relative-item">
                <a
                  href="/live/liveDetail?id=7311542281985739547"
                  tag="a"
                  target="_blank"
                  class="text-decoration-none a-active"
                  ><div class="top">
                    <img
                      src="//oss-image1.quanmamaimg.com/AdminImageUpload/2022101218625278_ORIGIN_0kzN.jpg"
                      alt="ddd-img"
                      class="label"
                    />
                    <span class="text ellipsis-1">万人团购真便宜 中国羽绒服溯源</span>
                  </div>
                  <div class="bottom text-left">
                    <span class="mr20">12/12 11:18</span
                    ><!---->
                  </div></a
                >
              </div>
              <!---->
            </div>
            <div class="flex justify-content-end mt20">
              <div
                class="default-add-btn relative flex justify-content-center align-items-center cursor-pointer"
              >
                <div class="icon-wrapper flex align-items-center justify-content-center">
                  <img
                    src="//oss-image3.quanmamaimg.com/AdminImageUpload/2022101218648930_ORIGIN_xAzM.jpg"
                    alt="ddd-img"
                    class="active"
                  />
                  <img
                    src="//oss-image2.quanmamaimg.com/AdminImageUpload/2022101218659946_ORIGIN_0YjM.jpg"
                    alt="ddd-img"
                    class="disabled"
                  />
                </div>
                <span class="text">立即收藏</span>
              </div>
            </div>
          </div>
          <div class="list-item pl20 pr20 cursor-pointer">
            <div class="author-info flex align-items-center relative">
              <a
                href="/author/authorDetail?userId=2057921998100264"
                tag="a"
                target="_blank"
                class="relative author-icon block text-decoration-none mr10 isLive"
                ><img
                  alt="ddd-img"
                  class="img-lazy author-icon"
                  data-src="http://image1.daduoduo.com/People/202212/445ddb6cb685459bb2ce766d1831c9b0?x-oss-process=image/resize,h_150,w_150"
                  src="http://image1.daduoduo.com/People/202212/445ddb6cb685459bb2ce766d1831c9b0?x-oss-process=image/resize,h_150,w_150"
                  lazy="loaded"
                  style="width: 50px; height: 50px" />
                <div class="isLiveLabel">
                  <img
                    src="//oss-image2.quanmamaimg.com/AdminImageUpload/2022/10/19/2324680living_ORIGIN_1EDM.gif"
                    alt="ddd-img"
                    class="icon"
                  />
                  <img
                    src="//oss-image3.quanmamaimg.com/AdminImageUpload/2022/10/12/520851_ORIGIN_1UjN.png"
                    alt="ddd-img"
                    class="text"
                  /></div
              ></a>
              <div class="flex-1 flex flex-direction-column justify-content-center">
                <div class="flex align-items-center">
                  <a
                    href="/author/authorDetail?userId=2057921998100264"
                    tag="a"
                    target="_blank"
                    class="flex align-items-center relative text-decoration-none ellipsis-1 author-hover c333"
                    ><span class="nickname fs14 c333 font-weight-600 ellipsis-1"
                      >新疆和田玉老郑</span
                    ></a
                  >
                  <div class="info-menu-wrapper">
                    <div class="icon-menu-wrapper">
                      <img
                        src="//oss-image1.quanmamaimg.com/AdminImageUpload/2022101218541228_ORIGIN_yYDM.jpg"
                        alt="ddd-img"
                        class="active"
                      />
                      <img
                        src="//oss-image1.quanmamaimg.com/AdminImageUpload/2022101218613792_ORIGIN_2IzM.jpg"
                        alt="ddd-img"
                        class="disabled"
                      />
                    </div>
                    <div class="menu-wrapper">
                      <div class="menu-content collect_loading_104934349824">
                        <div class="menu-item">备注</div>
                        <div class="menu-item">分组</div>
                        <div class="menu-item">收藏</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="unique c999 fs12 ellipsis-1">账号 xinjiangheti27</div>
                <div class="flex align-items-center ellipsis-1" style="margin-top: 2px">
                  <span class="fs12 font-weight-400 cFD4C60">粉丝 118.9w</span>
                  <span class="fs12 font-weight-400 cFD4C60">（昨天+1,277）</span>
                </div>
              </div>
            </div>
            <div class="flex flex-direction-column">
              <div class="relative-item">
                <a
                  href="/live/liveDetail?id=7311552988923874098"
                  tag="a"
                  target="_blank"
                  class="text-decoration-none a-active"
                  ><div class="top">
                    <img
                      src="//oss-image1.quanmamaimg.com/AdminImageUpload/2022101218625278_ORIGIN_0kzN.jpg"
                      alt="ddd-img"
                      class="label"
                    />
                    <span class="text ellipsis-1">双12开始</span>
                  </div>
                  <div class="bottom text-left">
                    <span class="mr20">12/12 12:13</span
                    ><!---->
                  </div></a
                >
              </div>
              <!---->
            </div>
            <div class="flex justify-content-end mt20">
              <div
                class="default-add-btn relative flex justify-content-center align-items-center cursor-pointer"
              >
                <div class="icon-wrapper flex align-items-center justify-content-center">
                  <img
                    src="//oss-image3.quanmamaimg.com/AdminImageUpload/2022101218648930_ORIGIN_xAzM.jpg"
                    alt="ddd-img"
                    class="active"
                  />
                  <img
                    src="//oss-image2.quanmamaimg.com/AdminImageUpload/2022101218659946_ORIGIN_0YjM.jpg"
                    alt="ddd-img"
                    class="disabled"
                  />
                </div>
                <span class="text">立即收藏</span>
              </div>
            </div>
          </div>
          <div class="list-item pl20 pr20 cursor-pointer">
            <div class="author-info flex align-items-center relative">
              <a
                href="/author/authorDetail?userId=2384194153219051"
                tag="a"
                target="_blank"
                class="relative author-icon block text-decoration-none mr10 isLive"
                ><img
                  alt="ddd-img"
                  class="img-lazy author-icon"
                  data-src="http://image1.daduoduo.com/People/202210/aeb992bb15a34447ac4d645563a4272b?x-oss-process=image/resize,h_150,w_150"
                  src="http://image1.daduoduo.com/People/202210/aeb992bb15a34447ac4d645563a4272b?x-oss-process=image/resize,h_150,w_150"
                  lazy="loaded"
                  style="width: 50px; height: 50px" />
                <div class="isLiveLabel">
                  <img
                    src="//oss-image2.quanmamaimg.com/AdminImageUpload/2022/10/19/2324680living_ORIGIN_1EDM.gif"
                    alt="ddd-img"
                    class="icon"
                  />
                  <img
                    src="//oss-image3.quanmamaimg.com/AdminImageUpload/2022/10/12/520851_ORIGIN_1UjN.png"
                    alt="ddd-img"
                    class="text"
                  /></div
              ></a>
              <div class="flex-1 flex flex-direction-column justify-content-center">
                <div class="flex align-items-center">
                  <a
                    href="/author/authorDetail?userId=2384194153219051"
                    tag="a"
                    target="_blank"
                    class="flex align-items-center relative text-decoration-none ellipsis-1 author-hover c333"
                    ><span class="nickname fs14 c333 font-weight-600 ellipsis-1"
                      >东方甄选</span
                    ></a
                  >
                  <div class="info-menu-wrapper">
                    <div class="icon-menu-wrapper">
                      <img
                        src="//oss-image1.quanmamaimg.com/AdminImageUpload/2022101218541228_ORIGIN_yYDM.jpg"
                        alt="ddd-img"
                        class="active"
                      />
                      <img
                        src="//oss-image1.quanmamaimg.com/AdminImageUpload/2022101218613792_ORIGIN_2IzM.jpg"
                        alt="ddd-img"
                        class="disabled"
                      />
                    </div>
                    <div class="menu-wrapper">
                      <div class="menu-content collect_loading_104934349824">
                        <div class="menu-item">备注</div>
                        <div class="menu-item">分组</div>
                        <div class="menu-item">收藏</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="unique c999 fs12 ellipsis-1">账号 dongfangzhenxuan</div>
                <div class="flex align-items-center ellipsis-1" style="margin-top: 2px">
                  <span class="fs12 font-weight-400 cFD4C60">粉丝 3,110.5w</span>
                  <!---->
                </div>
              </div>
            </div>
            <div class="flex flex-direction-column">
              <div class="relative-item">
                <a
                  href="/live/liveDetail?id=7311487318664301321"
                  tag="a"
                  target="_blank"
                  class="text-decoration-none a-active"
                  ><div class="top">
                    <img
                      src="//oss-image1.quanmamaimg.com/AdminImageUpload/2022101218625278_ORIGIN_0kzN.jpg"
                      alt="ddd-img"
                      class="label"
                    />
                    <span class="text ellipsis-1">东方甄选，健康生活，快乐相伴</span>
                  </div>
                  <div class="bottom text-left">
                    <span class="mr20">12/12 07:47</span
                    ><!---->
                  </div></a
                >
              </div>
              <!---->
            </div>
            <div class="flex justify-content-end mt20">
              <div
                class="default-add-btn relative flex justify-content-center align-items-center cursor-pointer"
              >
                <div class="icon-wrapper flex align-items-center justify-content-center">
                  <img
                    src="//oss-image3.quanmamaimg.com/AdminImageUpload/2022101218648930_ORIGIN_xAzM.jpg"
                    alt="ddd-img"
                    class="active"
                  />
                  <img
                    src="//oss-image2.quanmamaimg.com/AdminImageUpload/2022101218659946_ORIGIN_0YjM.jpg"
                    alt="ddd-img"
                    class="disabled"
                  />
                </div>
                <span class="text">立即收藏</span>
              </div>
            </div>
          </div>
          <div class="list-item pl20 pr20 cursor-pointer">
            <div class="author-info flex align-items-center relative">
              <a
                href="/author/authorDetail?userId=62584397187"
                tag="a"
                target="_blank"
                class="relative author-icon block text-decoration-none mr10"
                ><img
                  alt="ddd-img"
                  class="img-lazy author-icon"
                  data-src="http://image1.daduoduo.com/People/202210/bd492daa8244437da812d110ef1ce58e?x-oss-process=image/resize,h_150,w_150"
                  src="http://image1.daduoduo.com/People/202210/bd492daa8244437da812d110ef1ce58e?x-oss-process=image/resize,h_150,w_150"
                  lazy="loaded"
                  style="width: 50px; height: 50px"
                />
                <!----></a
              >
              <div class="flex-1 flex flex-direction-column justify-content-center">
                <div class="flex align-items-center">
                  <a
                    href="/author/authorDetail?userId=62584397187"
                    tag="a"
                    target="_blank"
                    class="flex align-items-center relative text-decoration-none ellipsis-1 author-hover c333"
                    ><span class="nickname fs14 c333 font-weight-600 ellipsis-1"
                      >小小101❤️</span
                    ></a
                  >
                  <div class="info-menu-wrapper">
                    <div class="icon-menu-wrapper">
                      <img
                        src="//oss-image1.quanmamaimg.com/AdminImageUpload/2022101218541228_ORIGIN_yYDM.jpg"
                        alt="ddd-img"
                        class="active"
                      />
                      <img
                        src="//oss-image1.quanmamaimg.com/AdminImageUpload/2022101218613792_ORIGIN_2IzM.jpg"
                        alt="ddd-img"
                        class="disabled"
                      />
                    </div>
                    <div class="menu-wrapper">
                      <div class="menu-content collect_loading_104934349824">
                        <div class="menu-item">备注</div>
                        <div class="menu-item">分组</div>
                        <div class="menu-item">收藏</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="unique c999 fs12 ellipsis-1">账号 wa101</div>
                <div class="flex align-items-center ellipsis-1" style="margin-top: 2px">
                  <span class="fs12 font-weight-400 cFD4C60">粉丝 1,487.7w</span>
                  <span class="fs12 font-weight-400 cFD4C60">（昨天+3.1w）</span>
                </div>
              </div>
            </div>
            <div class="flex flex-direction-column">
              <div class="relative-item">
                <a
                  href="/live/liveDetail?id=7311182071345498892"
                  tag="a"
                  target="_blank"
                  class="text-decoration-none a-active"
                  ><div class="top">
                    <img
                      src="//oss-image1.quanmamaimg.com/AdminImageUpload/2022101218625278_ORIGIN_0kzN.jpg"
                      alt="ddd-img"
                      class="label"
                    />
                    <span class="text ellipsis-1">早上在家炸炸</span>
                  </div>
                  <div class="bottom text-left">
                    <span class="mr20">12/11 12:58</span
                    ><!---->
                  </div></a
                >
              </div>
              <!---->
            </div>
            <div class="flex justify-content-end mt20">
              <div
                class="default-add-btn relative flex justify-content-center align-items-center cursor-pointer"
              >
                <div class="icon-wrapper flex align-items-center justify-content-center">
                  <img
                    src="//oss-image3.quanmamaimg.com/AdminImageUpload/2022101218648930_ORIGIN_xAzM.jpg"
                    alt="ddd-img"
                    class="active"
                  />
                  <img
                    src="//oss-image2.quanmamaimg.com/AdminImageUpload/2022101218659946_ORIGIN_0YjM.jpg"
                    alt="ddd-img"
                    class="disabled"
                  />
                </div>
                <span class="text">立即收藏</span>
              </div>
            </div>
          </div>
        </div>
        <!---->
        <!---->
        <div class="group-mgr-dialog">
          <div class="el-dialog__wrapper" style="display: none">
            <div
              role="dialog"
              aria-modal="true"
              aria-label="分组管理"
              class="el-dialog"
              style="margin-top: 15vh; width: 500px"
            >
              <div class="el-dialog__header">
                <span class="el-dialog__title">分组管理</span
                ><button type="button" aria-label="Close" class="el-dialog__headerbtn">
                  <i class="el-dialog__close el-icon el-icon-close"></i>
                </button>
              </div>
              <!---->
              <div class="el-dialog__footer">
                <span class="flex align-items-center justify-content-end"
                  ><button type="button" class="el-button el-button--default">
                    <!----><!----><span> 取 消 </span>
                  </button>
                  <button type="button" class="el-button el-button--primary">
                    <!----><!----><span> 确 定 </span>
                  </button></span
                >
              </div>
            </div>
          </div>
          <div class="confirm-dialog-block">
            <div class="el-dialog__wrapper" style="display: none">
              <div
                role="dialog"
                aria-modal="true"
                aria-label="删除分组"
                class="el-dialog"
                style="margin-top: 15vh"
              >
                <div class="el-dialog__header">
                  <span class="el-dialog__title">删除分组</span
                  ><button type="button" aria-label="Close" class="el-dialog__headerbtn">
                    <i class="el-dialog__close el-icon el-icon-close"></i>
                  </button>
                </div>
                <!---->
                <div class="el-dialog__footer">
                  <span class="dialog-footer"
                    ><button type="button" class="el-button el-button--default">
                      <!----><!----><span> 取 消 </span>
                    </button>
                    <button type="button" class="el-button el-button--primary">
                      <!----><!----><span> 确 定 </span>
                    </button></span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="group-creat-dialog">
            <div class="el-dialog__wrapper" style="display: none">
              <div
                role="dialog"
                aria-modal="true"
                aria-label="新建分组"
                class="el-dialog"
                style="margin-top: 15vh"
              >
                <div class="el-dialog__header">
                  <span class="el-dialog__title">新建分组</span
                  ><button type="button" aria-label="Close" class="el-dialog__headerbtn">
                    <i class="el-dialog__close el-icon el-icon-close"></i>
                  </button>
                </div>
                <!---->
                <div class="el-dialog__footer">
                  <span class="dialog-footer"
                    ><button type="button" class="el-button el-button--default">
                      <!----><!----><span> 取 消 </span>
                    </button>
                    <button type="button" class="el-button el-button--primary">
                      <!----><!----><span> 确 定 </span>
                    </button></span
                  >
                </div>
              </div>
            </div>
            <div class="el-dialog__wrapper" style="display: none">
              <div
                role="dialog"
                aria-modal="true"
                aria-label="重命名"
                class="el-dialog"
                style="margin-top: 15vh"
              >
                <div class="el-dialog__header">
                  <span class="el-dialog__title">重命名</span
                  ><button type="button" aria-label="Close" class="el-dialog__headerbtn">
                    <i class="el-dialog__close el-icon el-icon-close"></i>
                  </button>
                </div>
                <!---->
                <div class="el-dialog__footer">
                  <span class="dialog-footer"
                    ><button type="button" class="el-button el-button--default">
                      <!----><!----><span> 取 消 </span>
                    </button>
                    <button type="button" class="el-button el-button--primary">
                      <!----><!----><span> 确 定 </span>
                    </button></span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="remark-favorite">
          <div class="el-dialog__wrapper" style="display: none">
            <div
              role="dialog"
              aria-modal="true"
              aria-label="dialog"
              class="el-dialog"
              style="margin-top: 15vh"
            >
              <div class="el-dialog__header">
                <span class="el-dialog__title"></span
                ><!---->
              </div>
              <!----><!---->
            </div>
          </div>
        </div>
        <div class="group-move-dialog">
          <div class="el-dialog__wrapper" style="display: none">
            <div
              role="dialog"
              aria-modal="true"
              aria-label="移动分组"
              class="el-dialog"
              style="margin-top: 15vh; width: 400px"
            >
              <div class="el-dialog__header">
                <span class="el-dialog__title">移动分组</span
                ><button type="button" aria-label="Close" class="el-dialog__headerbtn">
                  <i class="el-dialog__close el-icon el-icon-close"></i>
                </button>
              </div>
              <!---->
              <div class="el-dialog__footer">
                <span class="flex align-items-center justify-content-end"
                  ><button type="button" class="el-button el-button--default">
                    <!----><!----><span> 取 消 </span>
                  </button>
                  <button type="button" class="el-button el-button--primary">
                    <!----><!----><span> 确 定 </span>
                  </button></span
                >
              </div>
            </div>
          </div>
          <div class="prompt-dialog-block">
            <div class="el-dialog__wrapper" style="display: none">
              <div
                role="dialog"
                aria-modal="true"
                aria-label="新建分组"
                class="el-dialog"
                style="margin-top: 15vh"
              >
                <div class="el-dialog__header">
                  <span class="el-dialog__title">新建分组</span
                  ><button type="button" aria-label="Close" class="el-dialog__headerbtn">
                    <i class="el-dialog__close el-icon el-icon-close"></i>
                  </button>
                </div>
                <!---->
                <div class="el-dialog__footer">
                  <span class="dialog-footer"
                    ><button type="button" class="el-button el-button--default">
                      <!----><!----><span> 取 消 </span>
                    </button>
                    <button type="button" class="el-button el-button--primary">
                      <!----><!----><span> 确 定 </span>
                    </button></span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="confirm-dialog-block">
          <div class="el-dialog__wrapper" style="display: none">
            <div
              role="dialog"
              aria-modal="true"
              aria-label="取消收藏"
              class="el-dialog"
              style="margin-top: 15vh"
            >
              <div class="el-dialog__header">
                <span class="el-dialog__title">取消收藏</span
                ><button type="button" aria-label="Close" class="el-dialog__headerbtn">
                  <i class="el-dialog__close el-icon el-icon-close"></i>
                </button>
              </div>
              <!---->
              <div class="el-dialog__footer">
                <span class="dialog-footer"
                  ><button type="button" class="el-button el-button--default">
                    <!----><!----><span> 取 消 </span>
                  </button>
                  <button type="button" class="el-button el-button--primary">
                    <!----><!----><span> 确 定 </span>
                  </button></span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="live-trace-detail-dialog">
          <div class="el-dialog__wrapper" style="display: none">
            <div
              role="dialog"
              aria-modal="true"
              aria-label="dialog"
              class="el-dialog dialog"
              style="margin-top: 15vh"
            >
              <div class="el-dialog__header">
                <span class="el-dialog__title"></span
                ><!---->
              </div>
              <!----><!---->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>
<script>
// import xgbz from "@/components/tanchuang/xgbz.vue";
import CollectionData from "./Collection.json";

export default {
  data() {
    return {
      CollectionData,
      show_beizhu: false, //是否显示弹窗
      // 收藏类型数组
      radio3: "达人收藏",
      Collection: ["达人收藏", "商品收藏", "视频收藏", "小店收藏", "品牌收藏"],
    };
  },
  methods: {
    // 定义函数
    handleCommand(command) {
      switch (command) {
        case "a":
          this.show_beizhu = true;
          break;
        default:
          this.$message("click on item " + command);
          break;
      }
    },
  },
  components: {
    // xgbz,
  },
};
</script>

<style scoped lang="less">
/* scoped 让style样式 局部生效*/
@import '@/assets/css/dashboard.css';
@import '@/assets/css/base.css';
@import '@/assets/css/ddd-common.css';
@import '@/assets/css/ddd-common-components.css';
</style>
