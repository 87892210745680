<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="connect">
    <span class="title">品类指数大盘</span>
    <div class="main">
      <div class="left">
        <Exponent></Exponent>
      </div>
      <div class="right">
        <div
          class="card-main"
          v-for="(item, index) in targetlist"
          :key="index"
        >
          <div class="title">
            <span>{{ item.title }}</span>
          </div>
          <div class="num">
            <span>{{ item.num }}</span>
          </div>
          <div class="Compare">
            <span style="margin-right: 5px">较前日</span>
            <div class="Compare_main">
              <span
                class="Figures"
                :style="{ color: item.increase ? '#ff3b30' : '#16cc96' }"
                >{{ item.Compare }}</span
              >
              <span
                class="icon"
                :style="{ color: item.increase ? '#ff3b30' : '#16cc96' }"
                >%</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Exponent from "../echarts/Exponent";
export default {
  data() {
    return {
      targetlist: [
        {
          title: "上架商品指数",
          num: "55.7",
          // 增减?
          increase: true,
          Compare: "+2.75",
        },
        {
          title: "新增商品指数",
          num: "5.3",
          increase: false,
          Compare: "-0.19",
        },
        {
          title: "客单价指数",
          num: "108.4",
          increase: true,
          Compare: "+6.91",
        },
        {
          title: "商品销量指数",
          num: "59.9",
          increase: false,
          Compare: "-1.58",
        },
        {
          title: "直播GMV指数",
          num: "64.9",
          increase: true,
          Compare: "+5.21",
        },
        {
          title: "抖音小店指数",
          num: "30.9",
          increase: true,
          Compare: "+1.31",
        },
      ],
    };
  },
  methods: {
    // 定义函数
  },
  components: {
    Exponent,
  },
};
</script>

<style scoped lang="less">
/* scoped 让style样式 局部生效*/
.connect {
  background-color: #fff;
  box-shadow: 0 3px 15px 0 hsla(0, 0%, 48.2%, 0.1);
  margin: 15px 0;
  padding: 25px 0px 20px 0px;
  width: 1200px;
  height: 469px;
  .title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 25px;
    color: #333;
    line-height: 100%;
  }
  .main {
    margin: 50px 0 20px 0;
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
    align-items: center;
    .left {
      width: 700px;
      // background-color: yellow;
      height: 326px;
      border-radius: 10px;
    }
    .right {
      width: 500px;
      height: 100%;
      display: flex;
      flex-wrap: wrap;

      .card-main {
        font-size: 14px;
        margin: 0;
        box-sizing: border-box;
        cursor: pointer;
        position: relative;
        width: 150px;
        height: 138px;
        padding: 16px 20px;
        color: #333;
        border-radius: 10px;
        border: 1px solid transparent;
        box-shadow: 0 5px 20px 0 hsla(0, 0%, 45.1%, 0.1);
        .title {
          cursor: pointer;
          color: #333;
          margin: 0;
          padding: 0;
          box-sizing: border-box;
          display: flex;
          font-weight: 600;
          font-size: 16px;
          align-items: center;
          span {
            cursor: pointer;
            color: #333;
            font-weight: 600;
            font-size: 16px;
            margin: 0;
            padding: 0;
            box-sizing: border-box;

            margin-right: 6px;
          }
        }
        .num {
          text-align: left;
          font-size: 14px;
          cursor: pointer;
          color: #333;
          margin: 0;
          padding: 0;
          box-sizing: border-box;
          font-weight: 400;
          margin-top: 25px;
          span {
            cursor: pointer;
            color: #333;
            font-weight: 400;
            margin: 0;
            padding: 0;
            box-sizing: border-box;
            -webkit-font-smoothing: antialiased;
            font-size: 24px;
            line-height: 100%;
            font-family: CDSHT-Regular, CDSHT;
          }
        }
        .Compare {
          cursor: pointer;
          color: #333;
          margin: 0;
          padding: 0;
          box-sizing: border-box;
          display: flex;
          position: relative;
          margin-top: 10px;
          font-size: 12px;
          align-items: center;
          span {
            cursor: pointer;
            font-size: 12px;
            margin: 0;
            padding: 0;
            box-sizing: border-box;
            margin-right: 5px;
            color: #666;
          }
          .Compare_main {
            line-height: 17px;
            cursor: pointer;
            font-weight: 400;
            font-size: 14px;
            color: #16cc96;
            margin: 0;
            padding: 0;
            box-sizing: border-box;
            display: flex;
            align-items: baseline;
            position: relative;
            top: 1px;
            .Figures {
              line-height: 17px;
              cursor: pointer;
              font-weight: 400;
              color: #16cc96;
              margin: 0;
              padding: 0;
              box-sizing: border-box;
              -webkit-font-smoothing: antialiased;
              font-size: 14px;
              line-height: 100%;
              font-family: CDSHT-Regular, CDSHT;
            }
            .icon {
              cursor: pointer;
              color: #16cc96;
              margin: 0;
              padding: 0;
              box-sizing: border-box;
              -webkit-font-smoothing: antialiased;
              font-weight: 400;
              font-size: 12px;
              line-height: 100%;
              font-family: CDSHT-Regular, CDSHT;
              padding-left: 2px;
              padding-right: 2px;
            }
          }
        }
      }

      .card-main:hover {
        border: 1px solid #ff4d4f;
      }
    }
  }
}
</style>
