<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div>
        <router-view></router-view>
    </div>
</template>
<script>
export default {
    data() {
        return {
            FrameList: [],
        }
    },
    methods: {
        // 定义函数
    },
}
</script>
 
<style scoped>
/* scoped 让style样式 局部生效*/
</style>