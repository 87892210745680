<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="connect">
    <span class="title">直播电商流量指数</span>
    <div class="main">
      <div class="left">
        <Folwline></Folwline>
      </div>
      <div class="right">
        <el-card
          class="box-card"
          v-for="(item, index) in targetlist"
          :key="index"
        >
          <div class="card-main">
            <div class="title">{{ item.title }}</div>
            <div class="num">{{ item.num }}</div>
            <div class="Compare">
              较前日
              <span :style="{ color: item.increase ? '#ff3b30' : '#16cc96' }">{{
                item.Compare
              }}</span>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
import Folwline from "../echarts/Folwline";
export default {
  data() {
    return {
      targetlist: [
        {
          title: "直播场次指数",
          num: "42.7",
          // 增减?
          increase: true,
          Compare: "+0.90%",
        },
        {
          title: "观看人次指数",
          num: "56.9",
          increase: false,
          Compare: "-1.39%",
        },
        {
          title: "热销商品指数",
          num: "56.9",
          increase: true,
          Compare: "+1.97%",
        },
        {
          title: "直播GMV指数",
          num: "65.4",
          increase: true,
          Compare: "+0.58%",
        },
        {
          title: "直播销量指数",
          num: "59",
          increase: false,
          Compare: "-1.50%",
        },
        {
          title: "场均GMV指数",
          num: "15.3",
          increase: false,
          Compare: "-0.33%",
        },
      ],
    };
  },
  methods: {
    // 定义函数
  },
  components: {
    Folwline,
  },
};
</script>

<style scoped lang="less">
/* scoped 让style样式 局部生效*/

.connect {
  background-color: #fff;
  padding: 25px 0 20px 0;
  margin-bottom: 15px;
  box-shadow: 0 3px 15px 0 hsla(0, 0%, 48.2%, 0.1);

  .title {
    font-size: 20px;
    font-weight: 600;
  }
  .main {
    margin-top: 30px;
    width: 1200px;
    height: 326px;
    display: flex;
    flex-wrap: nowrap;
    .left {
      width: 700px;
      // background-color: yellow;
      height: 326px;
      border-radius: 10px;
    }
    .right {
      margin-left: 10px;
      width: 500px;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      .box-card {
        margin: 5px;
        width: 150px;
        height: 138px;
        // 圆角
        border-radius: 10px;
        .card-main {
          .title {
            cursor: pointer;
            color: #333;
            margin: 0;
            padding: 0;
            box-sizing: border-box;

            -webkit-font-smoothing: antialiased;
            display: flex;
            font-weight: 600;
            font-size: 16px;
            align-items: center;
          }
          .num {
            text-align: left;
            margin-top: 15px;
            font-size: 24px;
            font-weight: 600;
            color: #333;
          }
          .Compare {
            cursor: pointer;
            color: #333;
            margin: 0;
            padding: 0;
            box-sizing: border-box;
            display: flex;
            position: relative;
            margin-top: 10px;
            font-size: 12px;
            align-items: center;
            span {
              cursor: pointer;
              font-weight: 400;
              color: #ff3b30;
              margin: 0;
              padding: 0;
              box-sizing: border-box;
              font-size: 14px;
              line-height: 100%;
              font-family: CDSHT-Regular, CDSHT;
            }
          }
        }
      }
      .box-card:hover {
        border: 1px solid #ff4d4f;
      }
    }
  }
}
</style>
