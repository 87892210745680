<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="search">
    <div class="search_title">
      <div class="Rolling">
        <el-carousel
          type="default"
          direction="vertical"
          :initial-index="0"
          :autoplay="true"
          :interval="3000"
          :loop="true"
          trigger="hover"
          indicator-position="none"
          arrow="hover"
          height="28px"
        >
          <div class="titleSwiper">
            <el-carousel-item v-for="(item, index) in RollingList" :key="index">
              <div>{{ item.label }}</div>
            </el-carousel-item>
          </div>
        </el-carousel>
      </div>
      <span class="h1">达多多</span>用数据助力直播决策
    </div>
    <div class="search-box-block" style="margin-top: 30px">
      <ul class="align-items-center">
        <li
          @click="sel_serach_type(index)"
          :class="[serach_type == index ? 'active item' : 'item']"
          v-for="(item, index) in serach_arr"
          :key="index"
        >
          搜{{ item }}
        </li>
      </ul>
      <div class="search-input-war">
        <div class="w100p">
          <div class="w-full h-[44px] flex bg-white rounded-[8px] px-[30px] relative">
              <input type="text" class="flex-1 outline-none border-none text-[#606266] leading-[44px]" placeholder="请输入达人名称、抖音号或达人主页链接">
              <i class="el-icon-search absolute right-[25px] top-[50%] translate-y-[-50%] cursor-pointer text-[20px] text-[#c0c4cc] font-[800]"></i>
          </div>
        </div>
        <div class="history-box"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      serach_type: 0,
      search_connect: "",
      serach_arr: ["达人", "商品", "直播", "视频", "小店", "品牌"],
      RollingList: [
        {
          index: 1,
          label: "服务三只羊、董先生等行业头部客户",
        },
        {
          index: 2,
          label: "行业价格屠夫，年卡会员低至798/年",
        },
        {
          index: 3,
          label: "这是第三条字幕",
        },
      ],
    };
  },
  methods: {
    // 定义函数
    sel_serach_type(index) {
      console.log("当前选中", index);
      this.serach_type = index;
    },
  },
  //  计算属性
  computed: {
    serach_ipt_plac() {
      let connect = "";
      switch (this.serach_type) {
        case 0:
          connect = "请输入达人名称、抖音号或达人主页链接";
          break;
        case 1:
          connect = "请输入商品标题、关键词或商品链接";
          break;
        case 2:
          connect = "请输入达人名称或直播标题";
          break;
        case 3:
          connect = "请输入视频标题或达人名称";
          break;
        case 4:
          connect = "请输入小店店铺标题";
          break;
        case 5:
          connect = "请输入品牌名";
          break;
      }
      return connect;
    },
  },
};
</script>

<style scoped lang="less">
/* scoped 让style样式 局部生效*/
.search {
  padding: 0;
  margin: 0;
  width: 100%;
  font-size: 14px;
  margin: 0;
  padding: 0;
  margin-top: 52px;
  box-sizing: border-box;
  height: 330px;
  background: url(//oss-image1.quanmamaimg.com/AdminImageUpload/2022/10/31/262963dddpc_tb_ORIGIN_2ITM.png)
    no-repeat center 80px #fafafa;
  background-attachment: fixed;
  color: #fff;
  margin-bottom: 30px;

  .search-box-block {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    font-size: 14px;
    color: #fff;
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
    font-family: SF Pro SC, SF Pro Text, SF Pro Icons, PingFang SC,
      Helvetica Neue, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    padding-bottom: 30px;

    .search-input-war {
      width: 821px;
      font-size: 14px;
      color: #fff;
      padding: 0;
      box-sizing: border-box;
      font-family: SF Pro SC, SF Pro Text, SF Pro Icons, PingFang SC,
        Helvetica Neue, Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      display: flex;
      position: relative;
      align-items: center;
      margin: 0 auto 0;
      width: 821px;

      .w100p {
        font-size: 14px;
        color: #fff;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: SF Pro SC, SF Pro Text, SF Pro Icons, PingFang SC,
          Helvetica Neue, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        width: 100%;
        /deep/ .el-input {
          height: 44px !important;
        }
      }
    }

    .align-items-center {
      width: 821px;
      font-size: 14px;
      color: #fff;
      margin: 0;
      padding: 0;
      margin-left: 30px;
      box-sizing: border-box;
      font-family: SF Pro SC, SF Pro Text, SF Pro Icons, PingFang SC,
        Helvetica Neue, Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      list-style: none;
      display: flex;
      margin-bottom: 20px;
      justify-content: center;
      align-items: center;

      .item {
        margin: 0;
        box-sizing: border-box;
        font-family: SF Pro SC, SF Pro Text, SF Pro Icons, PingFang SC,
          Helvetica Neue, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        list-style: none;
        display: flex;
        padding: 8px 0 8px 24px;
        width: 90px;
        height: 36px;
        font-size: 14px;
        border-radius: 100px;
        cursor: pointer;
        margin-right: 30px;
        white-space: nowrap;
        //
        color: #fff;
      }

      .active {
        background: #fd4c60;
      }
    }
  }

  .Rolling {
    padding-top: 35px;
    height: 60px;
  }
  .titleSwiper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-48%, -50%);
    min-height: 25px;
    font-size: 14px;
    background: #fff;
    color: #fd4c60;
    padding: 0 5px;
    border-radius: 10px 10px 10px 0;
    width: 250px;
    text-align: center;
    line-height: 25px;
    font-weight: 700;
  }

  .search_title {
    position: relative;
    margin-bottom: 30px;
    font-size: 36px;
    color: #fff;
    font-weight: 700;
    font-size: 36px;
    margin: 0;
    padding: 0;
    margin-bottom: -5px;
    box-sizing: border-box;
    font-family: SF Pro SC, SF Pro Text, SF Pro Icons, PingFang SC,
      Helvetica Neue, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    .h1 {
      margin-right: 18px;
      box-sizing: border-box;
    }
  }
}
input::placeholder {
  color: #c0c4cc;
}
</style>
